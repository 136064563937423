import { useContext, useEffect, useState } from "react";
import {
  IAudiosPreviewDto,
  IAudiosPreviewDtoWithIsUsed,
} from "../../../../dtos/sound";
import { GetAudiosPreviewData } from "../../../../api/sound";
import { DigitalBroadcastingContext } from "../..";
import { AddMetaShower, GetItemData } from "../../../../api/metashower";
import App from "antd/es/app";
import { AddMetaShowerCommand } from "../../../../dtos/metashower";

export const useAction = ({
  getPreviewData,
}: {
  getPreviewData: () => AddMetaShowerCommand;
}) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const { message } = App.useApp();

  const { broadcastContent, cursorParams, setBroadcastContent } = useContext(
    DigitalBroadcastingContext
  );

  const [isLoading, setIsLoadIng] = useState<boolean>(false);

  const [soundList, setSoundList] = useState<IAudiosPreviewDtoWithIsUsed[]>([]);

  const [previewGenerationLoading, setPreviewGenerationLoading] =
    useState<boolean>(false);

  const compareFunction = (
    preData: IAudiosPreviewDto,
    nextData: IAudiosPreviewDto,
    key: keyof IAudiosPreviewDto
  ) => {
    if (preData[key] > nextData[key]) return 1;
    if (preData[key] < nextData[key]) return -1;

    return 0;
  };

  const changeSoundListState = (id: string) => {
    const newSoundList = soundList.map((item) => {
      return { ...item, isUsed: item.id === id };
    });

    setSoundList(() => newSoundList);
  };

  const insertSSML = (SSML: string) => {
    const beforeCursorText = broadcastContent.substring(
      0,
      cursorParams.cursorStart
    );

    const afterCursorText = broadcastContent.substring(cursorParams.cursorEnd!);

    setBroadcastContent(() => beforeCursorText + SSML + afterCursorText);
  };

  const previewAudio = async () => {
    const data = getPreviewData();

    const isHascontent =
      data.text
        .replace(/<speak>/, "")
        .replace(/<\/speak>/, "")
        .trim() === "";

    if (!isHascontent) {
      if (data.language) {
        await AddMetaShower(data)
          .then((res) => {
            if (res && res.id) {
              setPreviewGenerationLoading(true);

              const newIntervalId = setInterval(
                () => loadAudioData(res.id),
                5000
              );

              setIntervalId(newIntervalId);
            }
          })
          .catch(() => {
            message.error("生成预览音频失败，请重试");
          });
      } else {
        message.info("未选择发音!");
      }
    } else {
      message.info("播报内容为空!");
    }
  };

  const loadAudioData = async (id: number) => {
    await GetItemData(id)
      .then((res) => {
        if (res && res?.mediaUrl) {
          intervalId && clearInterval(intervalId);

          setPreviewGenerationLoading(false);

          playAudio(res.mediaUrl);
        }
      })
      .catch((err) => {
        setPreviewGenerationLoading(false);

        message.error((err as Error).message);
      });
  };

  const playAudio = (url: string) => {
    const audio = document.createElement("audio");

    document.body.appendChild(audio);

    audio.src = url;

    const audioFinished = new Promise((resolve) => {
      audio.addEventListener("ended", resolve, { once: true });
    });

    audio.play();

    audioFinished.then(() => document.body.removeChild(audio));
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    setIsLoadIng(true);

    GetAudiosPreviewData()
      .then((response) => {
        if (response) {
          const newSoundList = response?.map((item) => ({
            ...item,
            isUsed: false,
          }));

          setTimeout(() => {
            setSoundList(newSoundList);
            setIsLoadIng(false);
          }, 500);
        }
      })
      .catch((err) => {
        message.error((err as Error).message);

        setTimeout(() => {
          setSoundList([]);
          setIsLoadIng(false);
        }, 500);
      });
  }, []);

  return {
    isLoading,
    compareFunction,
    soundList,
    changeSoundListState,
    insertSSML,
    previewGenerationLoading,
    previewAudio,
    playAudio,
  };
};
