export enum GenderTypeEnum {
  Male,
  Famale,
}

export enum LanguageTypeEnum {
  EnCn,
  Canton,
}

export interface IVoiceCloneRequest {
  genderType: GenderTypeEnum;
  name: string;
  type: LanguageTypeEnum;
  text?: string;
  dataset?: string;
}
