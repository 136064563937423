import {
  SsmlTag,
  Tone,
  toneTitle,
} from "../pages/digital-broadcasting/components/ssml";

export const ExtractSsmlRgex: { [key in SsmlTag]: RegExp } = {
  [SsmlTag.PolyphonicCharacter]:
    /<phoneme\s+alphabet="([^"]+)"\s+ph="([^"]+)">([^<]+)<\/phoneme>/g,
  [SsmlTag.Continuous]: /null/g,
  [SsmlTag.Reading]: /<say-as\s+interpret-as="ordinal">([^<]+)<\/say-as>/g,
  [SsmlTag.Pause]: /<break\s+time="500ms"+\/>/g,
  [SsmlTag.Ventilation]: /<break\s+time="200ms"+\/>/g,
  [SsmlTag.Digit]: /<say-as\s+interpret-as="digits">([^<]+)<\/say-as>/g,
  [SsmlTag.ReadingNumbers]:
    /<say-as\s+interpret-as="cardinal">([^<]+)<\/say-as>/g,
};

export const SsmlTagName: { [key in SsmlTag]: string } = {
  [SsmlTag.PolyphonicCharacter]: "phoneme",
  [SsmlTag.Continuous]: "",
  [SsmlTag.Reading]: "say-as",
  [SsmlTag.Pause]: "break",
  [SsmlTag.Ventilation]: "break",
  [SsmlTag.Digit]: "say-as",
  [SsmlTag.ReadingNumbers]: "say-as",
};

export interface ITagAttributesKeys {
  index: string | null;
  length: string | null;
  type: string | null;
  key: string | null;
  label: string | null;
  ph: string | null;
}

export const getSmmlStyle = (type: SsmlTag, data: ITagAttributesKeys) => {
  switch (type) {
    case SsmlTag.PolyphonicCharacter:
      const phonemeList: string[] | undefined = data.ph?.split(" ");
      const toneList: Tone[] | undefined = phonemeList?.map((item) => {
        const tone = Number(item.match(/\d+/)?.[0]);

        const newTone = tone >= 1 && tone <= 5 ? tone : 1;

        return isNaN(newTone) ? 1 : newTone;
      });

      const phonemeNode = (toneList: Tone[]) => {
        return `[${toneList?.map((item, index) =>
          index === toneList.length - 1
            ? toneTitle[item]
            : toneTitle[item] + ","
        )}]`;
      };

      return `${
        data.label
      }<span contenteditable="false" class="ql-phoneme" index="${
        data.index
      }" length="${data.length}" type="${data.type}" label="${
        data.label
      }" key="${data.key}" ph="${
        data.ph
      }" isssmldom="true" style="color: rgb(138, 141, 149);">${
        toneList ? phonemeNode(toneList) : ""
      }</span>`;
    case SsmlTag.Reading:
      return `${data.label}<span contenteditable="false" class="ql-currentSsmlTag" label="${data.label}" length="${data.length}" type="${data.type}" key="${data.key}" isssmldom="true" style="color: rgb(138, 141, 149); background-color: rgb(198, 198, 198);">[读数字]</span>`;
    case SsmlTag.Pause:
      return `<span contenteditable="false" class="ql-currentSsmlTag" label="${data.label}" length="${data.length}" type="${data.type}" key="${data.key}" isssmldom="true" style="color: rgb(138, 141, 149); background-color: rgb(255, 212, 191);">[停顿]</span>`;
    case SsmlTag.Ventilation:
      return `${data.label}<span contenteditable="false" class="ql-currentSsmlTag" label="${data.label}" length="${data.length}" type="${data.type}" key="${data.key}" isssmldom="true" style="color: rgb(138, 141, 149); background-color: rgb(198, 198, 198);">[换气]</span>`;
    case SsmlTag.Digit:
      return `${data.label}<span contenteditable="false" class="ql-currentSsmlTag" label="${data.label}" length="${data.length}" type="${data.type}" key="${data.key}" isssmldom="true" style="color: rgb(138, 141, 149); background-color: rgb(202, 216, 250);">[数字]</span>`;
    case SsmlTag.ReadingNumbers:
      return `${data.label}<span contenteditable="false" class="ql-currentSsmlTag" label="${data.label}" length="${data.length}" type="${data.type}" key="${data.key}" isssmldom="true" style="color: rgb(138, 141, 149); background-color: rgb(198, 198, 198);">[读数]</span>`;
    default:
      return "";
  }
};

export const convertSsmls = (str: string, ssml: SsmlTag) => {
  const newStr = str.replace(
    new RegExp(ExtractSsmlRgex[ssml], "g"),
    (match) => {
      if (match === "null") return "";

      const div = document.createElement("div");
      div.innerHTML = match;
      const label = div.innerText;

      const tagStringList = match
        .replace(new RegExp(`<${SsmlTagName[ssml]} `, "g"), "<div ")
        .replace(new RegExp(`</${SsmlTagName[ssml]}>`, "g"), "</div>");

      const parser = new DOMParser();
      const allElement = parser.parseFromString(tagStringList, "text/html");
      const divElement = allElement.body.firstChild;

      const dataList = (divElement as HTMLDivElement).getAttributeNames();

      let newData: ITagAttributesKeys = {
        index: null,
        length: null,
        type: null,
        key: null,
        label: null,
        ph: null,
      };
      if (dataList?.length) {
        dataList.forEach((item) => {
          const key = item ? (item as keyof ITagAttributesKeys) : undefined;
          key &&
            (newData[key] = (divElement as HTMLDivElement).getAttribute(key));
        });
      }
      newData.type = String(ssml);
      newData.label = label;

      const styleHtml = getSmmlStyle(ssml, newData);

      return styleHtml ?? "";
    }
  );

  return newStr;
};

//转为ssml标签对应ui
export const convertSsml = (type: SsmlTag[], originalString: string) => {
  let currentText = originalString;

  type.forEach((item) => {
    currentText = convertSsmls(currentText, item);
  });

  const value = currentText.split("<speak>")[1].split("</speak>")[0];

  return value;
};
