import { useEffect, useState } from "react";
import { GetListData } from "../../../../api/metashower";
import {
  DataType,
  MediaPage,
  MetaShowerBatch,
  MetaShowerBatchStatus,
  SearchType,
} from "../../../../dtos/metashower";
import { useDebounce, useDebounceFn } from "ahooks";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

export const useAction = () => {
  const [list, setList] = useState<MetaShowerBatch[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const [isShowVideo, setIsShowVideo] = useState<boolean>(false);

  const [videoUrl, setVideoUrl] = useState<string>("");

  const [clickDetail, setClickDetail] = useState<boolean>(false);

  const [videoText, setText] = useState<string>("");

  const [totalMsg, setTotalMsg] = useState<number>(0);

  const [pageIndex, setPageIndex] = useState<number>(1);

  const [loadFlag, setLoadFlag] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const debouncedInputSearchValue = useDebounce(searchValue, { wait: 500 });

  const { newProductionStatus, setNewProductionStatus } = useAuth();

  const navigate = useNavigate();

  const fetchLoadData = async () => {
    await GetListData({
      PageIndex: pageIndex,
      PageSize: MediaPage.PageSize,
      DataType: DataType.Nomal,
      KeyWord: debouncedInputSearchValue,
      SearchType: SearchType.Title,
    })
      .then((res) => {
        if (res) {
          const itemList = res?.metaShowerBatches.filter((x) => !!x.text);

          const total: number = res && res.count > 0 ? res.count : 0;

          setTotalMsg(total);
          setList(itemList);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
          setList([]);
          setTotalMsg(0);
        }, 500);
      });
  };

  const clickVideo = (item: MetaShowerBatch) => {
    if (item.status === MetaShowerBatchStatus.Completed) {
      setIsShowVideo((prev) => !prev);
      setVideoUrl(item.mediaUrl);
    }
  };

  const { run: handleDebounceClick } = useDebounceFn(fetchLoadData, {
    wait: 500,
  });

  const onCancel = (type: string) => {
    if (type === "video") {
      setVideoUrl("");
      setIsShowVideo((prev) => !prev);
    } else {
      setClickDetail((prev) => !prev);
      setText("");
    }
  };

  const getPageNum = (page: number) => {
    setPageIndex(page);
  };

  const changeNewProductionStatus = (status: boolean) => {
    setNewProductionStatus(status);
  };

  const changeLoadFlag = () => {
    setLoadFlag((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    fetchLoadData();
  }, [pageIndex, loadFlag, debouncedInputSearchValue]);

  return {
    list,
    loading,
    clickVideo,
    videoUrl,
    isShowVideo,
    onCancel,
    videoText,
    clickDetail,
    totalMsg,
    getPageNum,
    handleDebounceClick,
    newProductionStatus,
    changeNewProductionStatus,
    changeLoadFlag,
    setSearchValue,
    navigate,
    pageIndex,
    setLoading,
    searchValue,
  };
};
