import {
  IPostAttachmentParam,
  MediaDataDto,
  MetaShowerMediaType,
  IAttachmentResponseDto,
  MedaiDataPageIndex,
} from "../../dtos/generation-item";
import {
  MetaShowerBatchDto,
  AddMetaShowerCommand,
  GetAttachUrl,
  GetMediaType,
  MediasDataType,
  AttachmentId,
  PostMediaParams,
  MetaShowerBatch,
  GetMediaFileContentData,
} from "../../dtos/metashower";

import { Get, Post } from "../http-client";

export const AddMetaShower = async (data: AddMetaShowerCommand) =>
  await Post<MetaShowerBatch>("/api/MetaShower/add", data);

export const GetListData = async (params: GetMediaType) =>
  await Get<MetaShowerBatchDto>(
    `/api/metashower/list?PageIndex=${params.PageIndex}&PageSize=${
      params.PageSize
    }&DataType=${params.DataType}&Keyword=${params.KeyWord ?? ""}&SortField=${
      params.SortField ?? "createdDate"
    }&SortDirection=${params.SortDirection ?? "desc"}&SearchType=${
      params.SearchType
    }`
  );

export const GetItemData = async (id: number) =>
  await Get<MetaShowerBatch>(`/api/MetaShower/get?MetaShowerBatchId=${id}`);

export const GetMediaData = async (
  data: MetaShowerMediaType,
  page: MedaiDataPageIndex
) =>
  await Get<MediaDataDto>(
    `/api/metashower/medias?PageIndex=${page.pageIndex}&PageSize=${page.pageSize}&MediaType=${data}`
  );

export const PostAttachment = async (data: IPostAttachmentParam) =>
  await Post<IAttachmentResponseDto[]>("/api/Attachment/attachments", data);

export const GetAttachId = async (params: GetMediaType) => {
  return await Get<MediasDataType>(
    `/api/MetaShower/medias?MediaType=${params.MediaType}&PageIndex=${
      params.PageIndex
    }&PageSize=${params.PageSize}&KeyWord=${params.KeyWord ?? ""}`
  );
};

export const PostAttachId = async (data: PostMediaParams) => {
  return await Post<MediasDataType[]>(`/api/MetaShower/medias`, data);
};

export const GetMediasUrl = async (data: AttachmentId) =>
  await Post<GetAttachUrl[]>(`/api/Attachment/attachments`, data);

export const GetMediaFileData = async (data: GetMediaFileContentData) =>
  await Get<GetAttachUrl>(
    `/api/Attachment/attachment?Id=${data.Id}&Type=${data.Type}`
  );

export const PostUrlImg = async (data: FormData) => {
  return await Post<GetAttachUrl>(`/api/Attachment/upload`, data);
};

export const DeleteMedia = async (mediaId: number) => {
  return await Post(`/api/MetaShower/media/delete?MediaId=${mediaId}`);
};

export const GenerateAiCopy = async (InputContent: string) => {
  return await Post(`/api/MetaShower/copyWriting?InputContent=${InputContent}`);
};

export const GetOneMedia = async (MetaShowerBatchId: number) => {
  return await Get<MetaShowerBatch>(
    `/api/MetaShower/get?MetaShowerBatchId=${MetaShowerBatchId}`
  );
};
