import { GetAttachUrl } from "../../dtos/metashower";
import { Avatar } from "../avatar";
import UploadModal from "../upload-modal";
import { useAction } from "./hook";
import { Button, ConfigProvider, Input, Pagination, Spin, Tooltip } from "antd";
import { noDataIcon } from "../no-data";
import { IManageProps } from "./props";
import Icon, {
  CloseOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";

export const Manage = (props: IManageProps) => {
  const { addTitle, pageType } = props;

  const {
    mediasUrlList,
    getPageNum,
    totalMsg,
    getAttachId,
    mediaIdList,
    setOpenModal,
    openModal,
    pageParams,
    isLoading,
    setSearchValue,
    searchValue,
  } = useAction(pageType);

  return (
    <div className="flex flex-col h-full justify-center">
      <div className="flex flex-col w-full h-full justify-center items-cemter">
        <div className="flex mx-4 mb-4 justify-between items-center max-sm:flex-wrap">
          <div className="flex mt-4">
            <Button
              type="primary"
              className="font-semibold w-[6.5rem] h-10 flex items-center justify-center cursor-pointer"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              {addTitle}
            </Button>
            <Tooltip
              className="ml-4"
              placement="rightTop"
              title={`建议分辨率为640*640，文件大小小于2MB，保持视觉元素居中`}
            >
              <QuestionCircleOutlined className="text-xl text-[#94B0F5]" />
            </Tooltip>
          </div>
          <div className="flex flex-row-reverse items-center flex-auto mt-4">
            <Input
              prefix={<SearchOutlined style={{ color: "#C2C5C8" }} />}
              suffix={
                <CloseOutlined
                  className="cursor-pointer text-[#C2C5C8]"
                  onClick={() => !!searchValue && setSearchValue("")}
                />
              }
              className="max-w-[25rem]"
              placeholder="Please Input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex flex-1 justify-center item-center">
            <ConfigProvider theme={{ token: { colorPrimary: "#4D7CEE" } }}>
              <Spin size="large" spinning={isLoading} className="self-center" />
            </ConfigProvider>
          </div>
        ) : !mediasUrlList.length ? (
          <div className="flex flex-1 justify-center">
            <Icon
              component={noDataIcon}
              className="flex items-start mt-[9rem]"
            />
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto no-scrollbar w-full h-full">
            <div className="grid grid-cols-[repeat(auto-fill,minmax(20.5rem,1fr))]">
              {mediasUrlList.map((item: GetAttachUrl, index: number) => {
                return (
                  <Avatar
                    item={item}
                    key={index}
                    getAttachId={getAttachId}
                    mediaId={
                      mediaIdList.find((x) => x.attachmentId === item.id)
                        ?.mediaId
                    }
                  />
                );
              })}
            </div>
          </div>
        )}

        <ConfigProvider theme={{ token: { colorPrimary: "#4D7CEE" } }}>
          <div className="h-[4rem] w-full flex flex-row-reverse overflow-hidden items-center">
            <Pagination
              className="flex-1 homePagination px-[1.5rem]"
              size="default"
              total={totalMsg}
              onChange={getPageNum}
              current={pageParams.pageIndex}
              pageSize={pageParams.pageSize}
              showSizeChanger={false}
            />
          </div>
        </ConfigProvider>
      </div>

      <UploadModal
        isOpenModal={openModal}
        pageType={pageType}
        modalTitle={addTitle}
        setOpenModal={setOpenModal}
        handleUpdateList={getAttachId}
      />
    </div>
  );
};
