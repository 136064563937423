import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import "./fonts/iconfont.css";
import Login from "./pages/login";
import routes from "./routes";
import MainPage from "./pages/main";
import RequireAuth from "./pages/auth";
import { ArmsMonitor } from "./pages/arms";
import AuthProvider from "./hooks/providers/authProvider";
import { App as AppWrapper, ConfigProvider } from "antd";
import { useAction } from "./App.hook";
import { MsgContextProvider } from "./pages/home/components/contexts/msgContext";

const App = () => {
  const { show } = useAction();
  const AuthRoutes = () =>
    routes.map((item, index) => {
      return (
        <Route
          path={item.path}
          element={<RequireAuth>{item.component}</RequireAuth>}
          key={index}
        >
          <Route path="" element={<Navigate to={"/home/project"} />} />

          {item.children?.map((childrenItem, childrenIndex) => {
            return (
              <Route
                path={childrenItem.path}
                element={childrenItem.component}
                key={childrenIndex}
              />
            );
          })}
        </Route>
      );
    });

  const router = () => {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<MainPage />}>
          <Route path="" element={<Navigate to={"/home"} />} />
          {AuthRoutes()}
        </Route>
      </Routes>
    );
  };

  return show ? (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4D7CEE",
          },
        }}
      >
        <AppWrapper>
          <ArmsMonitor>
            <AuthProvider>
              <MsgContextProvider>{router()}</MsgContextProvider>
            </AuthProvider>
          </ArmsMonitor>
        </AppWrapper>
      </ConfigProvider>
    </BrowserRouter>
  ) : (
    <></>
  );
};

export default App;
