import { MenuProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { App } from "antd";
import { DeleteMedia } from "../../api/metashower";
import { IAvatarProps } from "./props";
import { useDebounceFn } from "ahooks";

export enum FileType {
  Image,
  Video,
}

export const useAction = (props: IAvatarProps) => {
  const { message } = App.useApp();

  const [showDel, setShowDel] = useState<boolean>(false);

  const [fileType, setFileType] = useState<FileType | null>(null);

  const [videoImage, setVideoImage] = useState<string>("");

  const [isDelLoading, setIsDelLoading] = useState<boolean>(false);

  const stageRef = useRef<HTMLCanvasElement>(null);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "更多",
      className: "text-center",
    },
    {
      key: "2",
      label: "删除",
      className: "text-center",
      onClick: () => {
        setShowDel(true);
      },
    },
  ];

  const handleOk = useDebounceFn(
    () => {
      if (props.mediaId) {
        setIsDelLoading(true);
        DeleteMedia(props.mediaId)
          .then(() => {
            setShowDel(false);
            message.success("删除成功");

            setTimeout(() => {
              props.getAttachId();
            }, 500);
          })
          .catch(() => {
            setShowDel(false);
            message.error("删除失败");
          })
          .finally(() => {
            setIsDelLoading(false);
          });
      }
    },
    {
      wait: 500,
    }
  ).run;

  const handleCancel = () => {
    setShowDel(false);
  };

  const detectFileType = (fileUrl: string): Promise<FileType> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = fileUrl;

      img.onload = () => {
        resolve(FileType.Image);
      };

      img.onerror = () => {
        resolve(FileType.Video);
      };
    });
  };

  const parseVideoToGenerateImage = (url: string) => {
    try {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(blob);

          const video = document.createElement("video");

          video.width = stageRef.current?.width ?? 0;
          video.height = stageRef.current?.height ?? 0;
          video.setAttribute("cross0rigin", "Anonymous");
          video.src = objectUrl;
          video.currentTime = 2;
          video.muted = false;
          video.oncanplay = (e) => {
            let canvas = document.createElement("canvas");
            canvas.width = stageRef.current?.width ?? 0;
            canvas.height = stageRef.current?.height ?? 0;
            let ctx = canvas.getContext("2d");
            ctx && ctx.drawImage(video, 0, 0, video.width, video.height);
            let imgUrl = canvas.toDataURL();
            setVideoImage(imgUrl);
          };
        });
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      const type = await detectFileType(props.item.fileUrl);
      setFileType(type);
    };

    fetchData();
  }, [props.item.fileUrl]);

  useEffect(() => {
    if (fileType === FileType.Video && !!props.item.fileUrl) {
      // parseVideoToGenerateImage(props.item.fileUrl);
    }
  }, [props.item.fileUrl, fileType]);

  return {
    items,
    showDel,
    handleOk,
    handleCancel,
    fileType,
    videoImage,
    stageRef,
    isDelLoading,
  };
};
