import { useEffect, useState } from "react";
import { FileType } from "../../../../components/avatar/hook";

export const useAction = ({ fileUrl }: { fileUrl: string }) => {
  const [fileType, setFileType] = useState<FileType | null>(null);

  const detectFileType = (fileUrl: string): Promise<FileType> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = fileUrl;

      img.onload = () => {
        resolve(FileType.Image);
      };

      img.onerror = () => {
        resolve(FileType.Video);
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const type = await detectFileType(fileUrl);
      setFileType(type);
    };

    fetchData();
  }, [fileUrl]);

  return { fileType };
};
