import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { createContext } from "react";

interface AuthContextType {
  token: string;
  username: string;
  signin: (user: string, callback?: VoidFunction) => void;
  signout: (callback?: VoidFunction) => void;
  newProductionStatus: boolean;
  setNewProductionStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider(props: { children: React.ReactNode }) {
  const tokenKey = (window as any).appsettings.tokenKey as string;

  const userNameKey = (window as any).appsettings.userNameKey as string;

  const defaultToken = (localStorage.getItem(tokenKey) as string)
    ? (localStorage.getItem(tokenKey) as string)
    : "";

  const defaultUserName = (localStorage.getItem(userNameKey) as string)
    ? (localStorage.getItem(userNameKey) as string)
    : "";

  const [token, setToken] = React.useState<string>(defaultToken);

  const [username, setUserName] = React.useState<string>(defaultUserName);

  const [newProductionStatus, setNewProductionStatus] =
    useState<boolean>(false);

  const signin = (token: string, callback?: VoidFunction) => {
    setToken(token);
    const decoded = jwt_decode<{ unique_name: string }>(token);
    setUserName(decoded.unique_name);
    callback && callback();
  };

  const signout = (callback?: VoidFunction) => {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userNameKey);
    setToken("");
    setUserName("");
    callback && callback();
  };

  let value = {
    token,
    username,
    signin,
    signout,
    newProductionStatus,
    setNewProductionStatus,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
