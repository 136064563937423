import { useAction } from "./hook";
import { ConfigProvider, Tabs } from "antd";
import { createContext } from "react";

import "../../../../antd.css";

import { MetaShowerType } from "../../../../dtos/metashower";
import { DigitalSelectorProps } from "./props";
import { Avatar } from "../avatarAndBackGround";
import { PronounceN } from "../pronounce-new";

export interface IDigitalSelectorOptionsProps {
  value: MetaShowerType;
  label: string;
}

export const DigitalSelectorContext = createContext<{
  avatarMediaId: number | null;
  backgroundMediaId: number | null;
}>(null!);

export const DigitalSelector = (props: DigitalSelectorProps) => {
  const {
    handleGetImageUrl,
    getPreviewData,
    avatarMediaId,
    backgroundMediaId,
  } = props;

  const { updateBgAndAvatar, bgAndAvatar } = useAction({
    handleGetImageUrl,
  });

  return (
    <div className="flex flex-col items-center h-full bg-white px-[1rem] pb-[1rem] rounded-md shadow pt-[1.8125rem]">
      <DigitalSelectorContext.Provider
        value={{
          avatarMediaId,
          backgroundMediaId,
        }}
      >
        <div className="w-full h-full overflow-x-auto no-scrollbar">
          <div className="rounded-t w-full h-full">
            <Tabs
              type="card"
              className="w-full h-full digitalSelectorTabs"
              size="large"
              tabBarGutter={0}
              items={[
                {
                  label: "数字人形象",
                  key: "1",
                  children: (
                    <Avatar
                      type={MetaShowerType.Avatar}
                      bgAndAvatar={bgAndAvatar}
                      updateBgAndAvatar={updateBgAndAvatar}
                    />
                  ),
                },
                {
                  label: "發音",
                  key: "2",
                  children: <PronounceN getPreviewData={getPreviewData} />,
                },
                {
                  label: <div className="w-[100% / 3]">背景</div>,
                  key: "3",
                  children: (
                    <Avatar
                      type={MetaShowerType.Background}
                      bgAndAvatar={bgAndAvatar}
                      updateBgAndAvatar={updateBgAndAvatar}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </DigitalSelectorContext.Provider>
    </div>
  );
};
