import { Link, Outlet, useLocation } from "react-router-dom";
import "../../fonts/iconfont.css";
import routes from "../../routes";
import { useAction } from "./hook";
import { Dropdown, Avatar, Layout, Menu } from "antd";
import Icon, {
  RightOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import SubMenu from "antd/es/menu/SubMenu";
import { INavMenuItem } from "./props";

const MainPage = () => {
  const {
    username,
    openKey,
    selectedKeys,
    collapsed,
    signout,
    setCollapsed,
    setOpenKey,
    navigate,
    weatherToHideTheMenu,
    setNewProductionStatus,
  } = useAction();

  const location = useLocation();

  const items = [
    {
      key: "1",
      disabled: true,
      label: <div className="text-center">{username}</div>,
    },
    {
      key: "2",
      label: (
        <div className="text-center" onClick={() => signout()}>
          Login out
        </div>
      ),
    },
  ];

  const menuList: INavMenuItem[] = routes
    .filter((item) => !item.path.includes("/voiceClone"))
    .map((item) => {
      const newList: INavMenuItem = {
        key: item.path,
        label: item.name,
        icon: item.icon,
      };
      if (item.children) {
        newList.children = item.children.map((childrenItem) => {
          return {
            key: childrenItem.path,
            label:
              childrenItem.path === "/home/project/new" ? (
                <div
                  onClick={() =>
                    location.pathname === "/home/project" &&
                    setNewProductionStatus(true)
                  }
                >
                  {childrenItem.name}
                </div>
              ) : (
                <Link to={childrenItem.path}>{childrenItem.name}</Link>
              ),
          };
        });
      } else {
        newList.children = [
          {
            key: item.path,
            label: <></>,
          },
        ];
      }

      return newList;
    });

  const renderMenuItems = (data: INavMenuItem[]) => {
    return data.map((item) => {
      return (
        <SubMenu
          key={item.key}
          title={item.label}
          icon={
            <Icon
              component={() =>
                (item.icon &&
                  item.icon(selectedKeys[0].includes(item.key))) ?? <></>
              }
            />
          }
        >
          {item.children &&
            item.children[0].key !== item.key &&
            item.children.map((items) => {
              return (
                <Menu.Item key={items.key} title={items.label}>
                  {items.label}
                </Menu.Item>
              );
            })}
        </SubMenu>
      );
    });
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen bg-[#F4F7FE]">
      <div className="flex items-center flex-shrink-0 z-10 mt-3 w-full">
        <div className="bg-white mx-[1rem] h-[4rem] flex flex-row w-full justify-between rounded-lg">
          <div className="px-[1.5rem] flex flex-row items-center">
            <div className="w-[2rem] h-[2rem] bg-[#94b0f5] opacity-100"></div>
            <span className="ml-[1rem] font-bold tracking-tight">
              Metashower
            </span>
          </div>
          <Dropdown menu={{ items }} trigger={["click"]} placement="bottom">
            <div className="pr-[2.25rem] flex justify-end items-center text-base">
              <Avatar
                size={32}
                icon={<UserOutlined />}
                className="bg-[#DEDFE0]"
              />
              <span className="ml-[0.5rem]">{username}</span>
            </div>
          </Dropdown>
        </div>
      </div>
      <Layout
        className="flex justify-center w-full overflow-y-auto bg-[#F4F7FE]"
        hasSider
      >
        {!weatherToHideTheMenu && (
          <Sider
            trigger={null}
            collapsed={collapsed}
            onBreakpoint={(broken) => {
              broken ? setCollapsed(true) : setCollapsed(false);
            }}
            collapsedWidth={0}
            className="rounded-lg py-3 m-[1rem] item-color"
            style={{ backgroundColor: "white" }}
          >
            <Menu
              style={{ border: "none" }}
              openKeys={openKey}
              selectedKeys={selectedKeys}
              mode="inline"
              expandIcon={(isSubMenu) => {
                return isSubMenu.isOpen ? (
                  <UpOutlined style={{ fontSize: "0.7rem" }} />
                ) : (
                  <RightOutlined style={{ fontSize: "0.7rem" }} />
                );
              }}
              onOpenChange={(keyPath) => {
                setOpenKey([keyPath[1]]);
                navigate(
                  keyPath[1] === "/digitalBroadcasting/:id"
                    ? "/digitalBroadcasting/new"
                    : keyPath[1]
                );
              }}
              className="item-color"
            >
              {renderMenuItems(menuList)}
            </Menu>
          </Sider>
        )}

        <Layout className="overflow-y-auto">
          <Content>
            <div className="flex-1 bg-[#F4F7FE] overflow-y-auto h-full">
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default MainPage;
