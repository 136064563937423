export const EditIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="9959"
      id="mx_n_1700726162130"
      width="1.2rem"
      height="1.2rem"
    >
      <path
        d="M583.811536 229.827409c0 0-427.240571 428.043866-430.979732 431.92936-21.161972 21.98778-39.581484 58.826803-45.769416 80.091106-4.494361 15.44476-38.780235 132.218324-40.047088 141.119041-2.541893 16.532535-0.950651 28.288277 4.76963 35.279504 5.720282 6.996344 17.160845 9.541307 34.326806 7.631818 8.258081-1.27197 127.473253-37.401842 144.929834-42.911322 24.915459-7.864108 65.847707-29.353538 86.767156-49.578162 2.544963-1.910513 424.308799-424.306752 424.308799-424.306752L583.811536 229.827409zM886.87083 286.190091c26.241664-27.312042 32.234145-74.701352 5.628184-101.307314-18.165732-18.165732-73.502038-74.563206-84.422761-84.934414-21.396309-20.318768-64.979944-19.442818-99.353822 10.689457-6.794753 5.956665-45.76737 41.633213-53.393048 48.625464l178.294732 178.304965C838.078567 333.757456 879.240035 293.814746 886.87083 286.190091zM919.233911 800.10265c0 0-364.565113-0.053212-365.210819 0-5.557576 0.457418-10.615779 2.617617-14.404058 6.26775l-65.37187 81.086783c-3.433192 4.087085-5.742794 9.172917-5.742794 14.927991 0 12.45466 9.765411 22.397103 22.01541 23.207561 0.51984 0.034792 426.859901 0 426.859901 0 28.039613 0 42.555212-9.886161 42.555212-30.749328 0-14.009062 0-52.79953 0-63.806211C959.935915 810.698986 948.554704 800.10265 919.233911 800.10265z"
        fill="#C2C5C8"
        p-id="9960"
      ></path>
    </svg>
  );
};

export const SelectIcon = () => {
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon">
        <path
          id="Vector"
          d="M4.94 5.71997L8 8.77997L11.06 5.71997L12 6.66664L8 10.6666L4 6.66664L4.94 5.71997Z"
          fill="#4D7CEE"
        />
      </g>
    </svg>
  );
};
