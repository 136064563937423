import { App } from "antd";
import { DeleteOrRestoreMedia } from "../../../../../../api/recycle-bin";
import { OperationType } from "../../../../../../dtos/recycle-bin";
import { useDebounceFn } from "ahooks";
import { useState } from "react";

export const useAction = ({
  changeLoadFlag,
}: {
  changeLoadFlag: () => void;
}) => {
  const { message } = App.useApp();

  const [showDel, setShowDel] = useState<boolean>(false);

  const [isDelLoading, setIsDelLoading] = useState<boolean>(false);

  const [delId, setDelId] = useState<string>("");

  const handleOk = useDebounceFn(
    () => {
      if (delId) {
        setIsDelLoading(true);
        DeleteOrRestoreMedia({
          metaShowerBatchUuids: [delId],
          operationType: OperationType.SoftDelete,
        })
          .then(() => {
            setTimeout(() => {
              changeLoadFlag();
            }, 500);
            setDelId("");
            message.success("删除成功!");
          })
          .catch(() => {
            message.error("删除失败!");
          })
          .finally(() => {
            setIsDelLoading(false);
          });
      }
    },
    {
      wait: 500,
    }
  ).run;

  const handleCancel = () => {
    setShowDel(false);
  };

  const popupModal = (uuid: string) => {
    setDelId(uuid);

    setShowDel(true);
  };

  const downloadVideo = useDebounceFn(
    async (fileUrl: string, fileName: string) => {
      try {
        fetch(fileUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const objectUrl = window.URL.createObjectURL(blob);

            const a = document.createElement("a");

            a.href = objectUrl;
            a.download = fileName;
            a.click();

            window.URL.revokeObjectURL(objectUrl);
          });
      } catch (error) {
        message.error("下载文件时发生错误");
      }
    },
    {
      wait: 1000,
    }
  ).run;

  const playAudio = (url: string) => {
    const audio = document.createElement("audio");

    document.body.appendChild(audio);

    audio.src = url;

    const audioFinished = new Promise((resolve) => {
      audio.addEventListener("ended", resolve, { once: true });
    });

    audio.play();

    audioFinished.then(() => document.body.removeChild(audio));
  };

  return {
    popupModal,
    downloadVideo,
    playAudio,
    handleOk,
    showDel,
    isDelLoading,
    handleCancel,
  };
};
