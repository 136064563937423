import { ModalVideoProps } from "../../dtos/modal-video";
import { Modal } from "antd";
import ReactPlayer from "react-player/lazy";
import { useAction } from "./hook";
import Icon, { CloseCircleFilled } from "@ant-design/icons";
import { videoFailLoad } from "../ video";
import { useMemo } from "react";

export const ModalVideo = (props: ModalVideoProps) => {
  const { videoUrl, onCancel, type, videoText, status } = props;

  const { isError, playVideoError, modalWidth, modalHetght, setIsError } =
    useAction();

  const errorElement = useMemo(() => {
    return (
      <div
        className="flex bg-black text-white text-lg"
        style={{ height: `${modalHetght}rem` }}
      >
        <div className="flex flex-1 flex-col items-center justify-center space-y-9">
          <Icon component={videoFailLoad} />
          <span>播放失敗,請重新加載</span>
        </div>
      </div>
    );
  }, [modalHetght]);

  return (
    <>
      <Modal
        open={status}
        width={"auto"}
        centered
        footer={null}
        maskClosable={false}
        onCancel={() => {
          onCancel(type);
          setIsError(false);
        }}
        closeIcon={
          <CloseCircleFilled className=" text-zinc-200 text-4xl opacity-60 hover:opacity-80" />
        }
        destroyOnClose={true}
        styles={{
          content: {
            padding: 0,
            overflow: "hidden",
            backgroundColor: "rgba(255,255,255,0)",
          },
        }}
      >
        {type === "video" && (
          <ReactPlayer
            width={"auto"}
            height={"80vh"}
            style={{
              objectFit: "cover",
              maxHeight: "100%",
            }}
            controls={true}
            url={!!videoUrl ? videoUrl : "错误的路径"}
            onError={() => {
              playVideoError();
            }}
            wrapper={isError ? () => errorElement : undefined}
          />
        )}
        {type === "text" && (
          <div className="max-h-[30rem] overflow-y-auto no-scrollbar">
            文字内容：{videoText ? videoText : "暂无内容"}
          </div>
        )}
      </Modal>
    </>
  );
};
