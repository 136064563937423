import { useRef } from "react";

export const useAction = () => {
  const decreaseIconRef = useRef<HTMLButtonElement>(null);

  const increaseIconRef = useRef<HTMLButtonElement>(null);

  const decreaseIntervalRef = useRef<NodeJS.Timer>();

  const increaseIntervalRef = useRef<NodeJS.Timer>();

  return {
    decreaseIconRef,
    increaseIconRef,
    decreaseIntervalRef,
    increaseIntervalRef,
  };
};
