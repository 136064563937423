import ConfigProvider from "antd/es/config-provider";
import Table, { ColumnsType } from "antd/es/table";
import { useAction } from "./hook";
import { IAudiosPreviewDtoWithIsUsed } from "../../../../dtos/sound";
import Button from "antd/es/button";
import "../../../../antd.css";

import Tooltip from "antd/es/tooltip";
import { IconSlider } from "../pronounce-new/components/slider";
import { useContext, useEffect } from "react";
import { DigitalBroadcastingContext } from "../..";
import App from "antd/es/app";
import { IPronounceNProps } from "./prop";
import { SelectIcon } from "../svg-icon";
import {
  CurrentSsmlTag,
  PhonemeBlot,
  SsmlTag,
  ssmlTagKey,
  ssmlTagTitle,
} from "../ssml";
import { Quill } from "react-quill";
import { CursorParams } from "../../props";
import { Popover } from "antd";

const pronounceButtonParentStyle = "flex justify-center max-w-max";

const pronounceButtonStyle =
  "bg-[#DBE5FC] border-0 rounded-lg px-[1.5rem] h-min py-[0.5rem] text-[#4D7CEE] max-lg:mr-[1rem] font-medium mb-[1rem]";

export const PronounceN = (props: IPronounceNProps) => {
  const { message } = App.useApp();

  const { getPreviewData } = props;

  const {
    volumeSize,
    setVolumeSize,
    toneSize,
    setToneSize,
    language,
    setLanguage,
    cursorParams,
    quillRef,
    setCursorParams,
  } = useContext(DigitalBroadcastingContext);

  const {
    isLoading,
    compareFunction,
    soundList,
    changeSoundListState,
    insertSSML,
    previewGenerationLoading,
    previewAudio,
    playAudio,
  } = useAction({ getPreviewData });

  const selectReadMethodList = [
    {
      value: SsmlTag.Digit,
      label: ssmlTagTitle[SsmlTag.Digit],
    },
    {
      value: SsmlTag.ReadingNumbers,
      label: ssmlTagTitle[SsmlTag.ReadingNumbers],
    },
    {
      value: SsmlTag.Reading,
      label: ssmlTagTitle[SsmlTag.Reading],
    },
  ];

  const columns: ColumnsType<IAudiosPreviewDtoWithIsUsed> = [
    {
      title: "名稱",
      dataIndex: "name",
      sorter: (preData, nextData) => compareFunction(preData, nextData, "id"),
      render: (_, record) => {
        return <span className="text-[.875rem] whitespace-nowrap">小花</span>;
      },
    },
    {
      title: "編號",
      dataIndex: "id",
      sorter: (preData, nextData) => compareFunction(preData, nextData, "id"),
    },
    {
      title: "語種",
      dataIndex: "text",
      sorter: (preData, nextData) => compareFunction(preData, nextData, "text"),
    },
    {
      title: "性别",
      dataIndex: "title",
      sorter: (preData, nextData) =>
        compareFunction(preData, nextData, "title"),
    },
    {
      title: "操作",
      width: 120,
      render: (_, record) => {
        return (
          <div className="flex text-[#fff]">
            <span
              className="text-[#4D7CEE] mr-4 rounded-none h-max cursor-pointer text-[.875rem] whitespace-nowrap"
              onClick={() => {
                playAudio(record.mediaUrl);
              }}
            >
              試聽
            </span>
            <span
              className={`text-[#4D7CEE] w-[3.125rem] rounded-none h-max cursor-pointer text-[.875rem] whitespace-nowrap
              ${
                record.language === Number(language) &&
                "text-[#8A8D95] border-[#475AEC]"
              }
              `}
              onClick={() => {
                changeSoundListState(record.id);
                setLanguage(record.language);
              }}
            >
              {record.language === Number(language) ? "已使用" : "使用"}
            </span>
          </div>
        );
      },
    },
  ];

  const handleChangeCursorParams = () => {
    const selection = quillRef.current?.getEditor().getSelection();
    const selectedText = quillRef.current
      ?.getEditor()
      .getText(selection?.index, selection?.length);

    selectedText &&
      setCursorParams({
        cursorEnd: selection?.length,
        cursorStart: selection?.index,
        selectedText: selectedText,
      });
  };

  const handleAddSmmlIdentification = (
    type: SsmlTag,
    selectedData: CursorParams,
    phonemeValue?: string
  ) => {
    if (quillRef && quillRef.current) {
      const { selectedText, cursorStart, cursorEnd } = selectedData;

      if (
        selectedText ||
        [SsmlTag.Ventilation, SsmlTag.Pause, SsmlTag.Continuous].includes(type)
      ) {
        const quill = quillRef.current.getEditor();
        if (type === SsmlTag.PolyphonicCharacter && phonemeValue === null)
          return;

        const index = (cursorStart ?? 0) + (cursorEnd ?? 0);

        quill.insertEmbed(
          index,
          ssmlTagKey[type],
          {
            value: phonemeValue,
            label: quillRef.current.getEditor().getText(cursorStart, cursorEnd),
            type,
            index,
            length: selectedText?.length,
          },
          "user"
        );
      }
    }
  };

  const handleCurrentAddSmmlIdentification = (type: SsmlTag) => {
    const selection = quillRef.current?.getEditor().getSelection();
    const selectedText = quillRef.current
      ?.getEditor()
      .getText(selection?.index, selection?.length);

    if (
      selectedText ||
      [SsmlTag.Ventilation, SsmlTag.Pause, SsmlTag.Continuous].includes(type)
    ) {
      handleAddSmmlIdentification(type, {
        cursorEnd: selection?.length,
        cursorStart: selection?.index,
        selectedText,
      });
    }
  };

  useEffect(() => {
    Quill.register(PhonemeBlot, true);
    Quill.register(CurrentSsmlTag, true);
  }, []);

  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <div className="w-full h-full flex flex-col">
        <div>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: "#F4F7FE",
                  headerSplitColor: "#F4F7FE",
                },
              },
            }}
          >
            <Table
              loading={isLoading}
              className="pronounce"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={soundList}
              pagination={false}
              scroll={{ y: 300, x: 400 }}
              locale={{
                triggerDesc: "点击降序",
                triggerAsc: "点击升序",
                cancelSort: "取消排序",
              }}
            />
          </ConfigProvider>
        </div>

        <div className="flex-[50%] pt-[1.375rem]">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#DBE5FC",
                colorPrimaryHover: "#4D7CEE",
                colorBgContainer: "#dbe5fcbf",
              },
            }}
          >
            <div className="flex w-full flex-wrap max-lg:justify-start justify-around">
              <div className={pronounceButtonParentStyle}>
                <Popover
                  content={
                    <div>
                      {selectReadMethodList.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="h-8 leading-8 text-center px-4 rounded cursor-pointer hover:bg-[#edf2fd] hover:text-[#4d7cee]"
                            onClick={() => {
                              cursorParams.selectedText
                                ? insertSSML(
                                    `${
                                      item.value === SsmlTag.Reading
                                        ? '<say-as interpret-as="cardinal">' +
                                          cursorParams.selectedText +
                                          "</say-as>"
                                        : cursorParams.selectedText
                                    }`
                                  )
                                : message.info("请先选择文本");

                              handleAddSmmlIdentification(
                                item.value,
                                cursorParams
                              );
                            }}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  }
                  openClassName="max-h-max"
                  trigger="click"
                  overlayInnerStyle={{ padding: 4 }}
                  placement="bottom"
                  arrow={false}
                >
                  <Button
                    className={`${pronounceButtonStyle} px-3 flex items-center`}
                    onClick={() => handleChangeCursorParams()}
                  >
                    <span className="mr-1">数字</span> <SelectIcon />
                  </Button>
                </Popover>
              </div>
              <div className={pronounceButtonParentStyle}>
                <Button
                  onClick={() => {
                    !cursorParams.selectedText &&
                      insertSSML('<break time="500ms">');

                    handleCurrentAddSmmlIdentification(SsmlTag.Pause);
                  }}
                  className={pronounceButtonStyle}
                >
                  停頓
                </Button>
              </div>
              <div className={pronounceButtonParentStyle}>
                <Button
                  className={pronounceButtonStyle}
                  onClick={() => {
                    handleCurrentAddSmmlIdentification(SsmlTag.Continuous);
                  }}
                >
                  連續
                </Button>
              </div>
              <div className={pronounceButtonParentStyle}>
                <Tooltip
                  placement="bottom"
                  title="字与字的拼音用空格分隔，拼音的数目必须与字数相等。每个拼音由发音和音调组成，音调为1~5的数字編號，其中”5”表示轻声"
                >
                  <Button
                    className={pronounceButtonStyle}
                    onClick={() => {
                      handleChangeCursorParams();
                      const pronunciation = prompt("请先选择文本，才能输入");

                      const selection = quillRef.current
                        ?.getEditor()
                        .getSelection();

                      const selectedText = quillRef.current
                        ?.getEditor()
                        .getText(selection?.index, selection?.length);

                      if (pronunciation && selectedText) {
                        handleAddSmmlIdentification(
                          SsmlTag.PolyphonicCharacter,
                          {
                            cursorEnd: selection?.length,
                            cursorStart: selection?.index,
                            selectedText,
                          },
                          pronunciation
                        );
                      }
                    }}
                  >
                    多音字
                  </Button>
                </Tooltip>
              </div>

              <div className={pronounceButtonParentStyle}>
                <Button
                  onClick={() => {
                    !cursorParams.selectedText &&
                      insertSSML('<break time="200ms">');

                    handleCurrentAddSmmlIdentification(SsmlTag.Ventilation);
                  }}
                  className={pronounceButtonStyle}
                >
                  換氣
                </Button>
              </div>
            </div>
          </ConfigProvider>
          <div className="text-[.875rem] p-[.75rem_1.375rem_1rem] my-[0.5rem]">
            <IconSlider
              title="音調"
              min={0}
              max={100}
              size={toneSize}
              setSize={setToneSize}
            />
            <IconSlider
              title="音量"
              min={0}
              max={100}
              size={volumeSize}
              setSize={setVolumeSize}
            />
          </div>
          <Button
            type="primary"
            block
            className="h-[3rem] font-bold rounded-lg"
            onClick={previewAudio}
            loading={previewGenerationLoading}
          >
            预览
          </Button>
        </div>
      </div>
    </ConfigProvider>
  );
};
