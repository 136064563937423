import { useAction } from "./hook";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Input,
  Modal,
  Space,
  Upload,
} from "antd";
import { IUploadModal } from "./props";
import { MetaShowerType } from "../../dtos/metashower";
import { PlusOutlined } from "@ant-design/icons";

const UploadModal = (props: IUploadModal) => {
  const {
    setUploadVal,
    handleCancel,
    setIsPublic,
    isPublic,
    fileList,
    handleSubmit,
    loading,
    uplaodVal,
    handleUploadImg,
    beforeUpload,
    imageUrl,
  } = useAction(props);

  const { isOpenModal, modalTitle, pageType } = props;

  const fileNames = fileList ? fileList[0]?.name.split(".") : undefined;

  return isOpenModal ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4D7CEE",
        },
      }}
    >
      <div>
        <Modal
          title={modalTitle}
          open={isOpenModal}
          onOk={handleSubmit}
          okText="提交"
          cancelText="取消"
          onCancel={handleCancel}
          maskClosable={false}
          width={480}
        >
          <div className="h-68 flex flex-col">
            <div className="w-full flex justify-center items-center">
              <Upload
                listType="text"
                fileList={fileList}
                showUploadList={false}
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleUploadImg}
                accept={
                  pageType === MetaShowerType.Avatar
                    ? ".jpg,.jpeg,.png"
                    : pageType === MetaShowerType.Background
                    ? ".jpg,.jpeg,.png,.mp4"
                    : ""
                }
              >
                {imageUrl ? (
                  !fileNames || fileNames[fileNames.length - 1] !== "mp4" ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className={`${
                        pageType === MetaShowerType.Avatar
                          ? "w-[10rem] h-[10rem]"
                          : pageType === MetaShowerType.Background
                          ? "w-[15.75rem] h-[11rem]"
                          : ""
                      } rounded-lg object-cover bg-[#F4F7FE] mt-5`}
                    />
                  ) : (
                    <video src={imageUrl} className="w-full h-64" />
                  )
                ) : (
                  <div
                    className={`flex flex-col mt-5 bg-[#F4F7FE] rounded-lg ${
                      pageType === MetaShowerType.Avatar
                        ? "w-[10rem] h-[10rem]"
                        : pageType === MetaShowerType.Background
                        ? "w-[15.75rem] h-[11rem]"
                        : ""
                    } border-dashed border border-[#E9E9E9] justify-center items-center`}
                  >
                    <PlusOutlined className="text-[#4D7CEE]" />
                    <span className="mt-[0.5rem] text-[0.75rem] text-[#94B0F5]">
                      {`支持JPG、PNG${
                        pageType === MetaShowerType.Avatar
                          ? ""
                          : pageType === MetaShowerType.Background
                          ? "、MP4格式"
                          : ""
                      }`}
                    </span>
                  </div>
                )}
              </Upload>
            </div>
            <div className="flex w-full pt-[1rem] gap-x-4">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#F4F7FE",
                    colorWhite: "#94B0F5",
                  },
                }}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    placeholder="请输入文件名"
                    value={uplaodVal.inputVal}
                    onChange={(e) =>
                      setUploadVal({ ...uplaodVal, inputVal: e.target.value })
                    }
                  />
                  <Upload
                    listType="text"
                    fileList={fileList}
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    customRequest={handleUploadImg}
                    className="w-24"
                    accept={
                      pageType === MetaShowerType.Avatar
                        ? ".jpg,.jpeg,.png"
                        : pageType === MetaShowerType.Background
                        ? ".jpg,.jpeg,.png,.mp4"
                        : ""
                    }
                  >
                    <Button
                      type="primary"
                      className="w-[4.75rem] text-[#4D7CEE]"
                      loading={loading}
                      disabled={loading}
                    >
                      上傳
                    </Button>
                  </Upload>
                </Space.Compact>
              </ConfigProvider>
            </div>
            <div className="flex flex-col mt-4 justify-center item-center">
              <Checkbox
                checked={isPublic}
                onClick={() => setIsPublic(!isPublic)}
              >
                是否選擇公開
              </Checkbox>
            </div>
          </div>
        </Modal>
      </div>
    </ConfigProvider>
  ) : (
    <div />
  );
};

export default UploadModal;
