import { ReactElement } from "react";
import Management from "../pages/management";
import Digitizing from "../pages/digitizing";
import Home from "../pages/home";
import Project from "../pages/home/components/project";
import DigitalBroadcasting from "../pages/digital-broadcasting";
import RecycleBin from "../pages/home/components/recycling";
import VoiceClone from "../pages/voice-clone";
import {
  backgroundManagementIcon,
  digitalAvatarIcon,
  digitalHumanBroadcastIcon,
  myProjectIcon,
} from "../components/router-icon";

export interface IRouteItem {
  name: string;
  path: string;
  component: ReactElement;
  icon: (isClick: boolean) => JSX.Element;
  children?: childrenProps[];
}

export interface childrenProps {
  name: string;
  path: string;
  component: ReactElement;
}

const routes: IRouteItem[] = [
  {
    name: "我的項目",
    path: "/home",
    icon: myProjectIcon,
    component: <Home />,
    children: [
      {
        name: "預覽",
        path: "/home/project",
        component: <Project />,
      },
      {
        name: "回收站",
        path: "/home/recycling",
        component: <RecycleBin />,
      },
      {
        name: "新建製作",
        path: `/home/project/new`,
        component: <Project />,
      },
    ],
  },
  {
    name: "數字人播報",
    path: "/digitalBroadcasting/:id",
    icon: digitalAvatarIcon,
    component: <DigitalBroadcasting />,
  },
  {
    name: "數字化身",
    path: "/digitizing",
    icon: digitalHumanBroadcastIcon,
    component: <Digitizing />,
  },
  {
    name: "背景管理",
    path: "/menagement",
    icon: backgroundManagementIcon,
    component: <Management />,
  },
  {
    name: "語音克隆",
    path: "/voiceClone",
    icon: myProjectIcon,
    component: <VoiceClone />,
  },
];

export default routes;
