import { useEffect, useState } from "react";
import { GetAttachId, GetMediasUrl } from "../../api/metashower";
import {
  GetAttachUrl,
  MetaShowerGlobalMediasType,
} from "../../dtos/metashower";
import { IMediaIdList, IPageProps } from "./props";
import useDebounce from "ahooks/lib/useDebounce";
import { App } from "antd";

export const useAction = (pageType: number) => {
  const { message } = App.useApp();

  const [mediasUrlList, setMediasUrlList] = useState<GetAttachUrl[]>([]);

  const [pageParams, setPageParams] = useState<IPageProps>({
    pageIndex: 1,
    pageSize: 50,
  });

  const [searchValue, setSearchValue] = useState<string>("");

  const [totalMsg, setTotalMsg] = useState<number>(0);

  const [mediaIdList, setMediaIdList] = useState<IMediaIdList[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const debouncedInputSearchValue = useDebounce(searchValue, { wait: 500 });

  const getAttachId = async () => {
    setIsLoading(true);

    GetAttachId({
      MediaType: pageType,
      PageIndex: pageParams.pageIndex,
      PageSize: pageParams.pageSize,
      KeyWord: debouncedInputSearchValue,
    })
      .then(async (res) => {
        setTotalMsg(res?.count ?? 0);

        const newMediaIdList: IMediaIdList[] = [];

        const metaShowerGlobalMedias = res?.metaShowerGlobalMedias ?? [];

        const attachmentIdList = metaShowerGlobalMedias.map(
          (item: MetaShowerGlobalMediasType) => {
            newMediaIdList.push({
              attachmentId: item.attachmentId,
              mediaId: item.id,
            });
            return item.attachmentId;
          }
        );

        if (attachmentIdList.length > 0) {
          GetMediasUrl({
            attachmentIds: attachmentIdList,
          })
            .then((res) => {
              setMediaIdList(newMediaIdList);
              handlerNewArr(metaShowerGlobalMedias, res ?? [], () => {
                setTimeout(() => {
                  setIsLoading(false);
                }, 800);
              });
            })
            .catch(() => {
              setMediasUrlList([]);
              setMediaIdList([]);
              setIsLoading(false);
              message.error("獲取數據失敗,請重試");
            });
        } else {
          setMediasUrlList([]);
          setMediaIdList([]);
        }
      })
      .catch(() => {
        setTotalMsg(0);
        setMediaIdList([]);
        setMediasUrlList([]);
        setIsLoading(false);
        message.error("獲取數據失敗,請重試");
      });
  };

  const handlerNewArr = (
    ids: MetaShowerGlobalMediasType[],
    list: GetAttachUrl[],
    callback?: () => void
  ) => {
    const newArr = list.map((listItem) => {
      const getIndex = ids.filter((idsItem) => {
        if (listItem?.id === idsItem?.attachmentId) {
          return idsItem;
        }
      });

      return {
        ...listItem,
        title: getIndex[0].title,
        sequentialNumber: getIndex[0].sequentialNumber,
      };
    });

    setMediasUrlList(newArr);

    callback && callback();
  };

  useEffect(() => {
    getAttachId();
  }, [pageParams.pageIndex, debouncedInputSearchValue]);

  const getPageNum = (page: number, pageSize: number) => {
    setPageParams({ ...pageParams, pageIndex: page });
  };

  return {
    mediasUrlList,
    getPageNum,
    totalMsg,
    getAttachId,
    mediaIdList,
    setOpenModal,
    openModal,
    pageParams,
    isLoading,
    setSearchValue,
    searchValue,
  };
};
