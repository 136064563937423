import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PostLgoin } from "../../api/login";
import useAuth from "../../hooks/useAuth";

export const useAction = () => {
  const rememberKey = localStorage.getItem("rememberKey");

  const { signin } = useAuth();

  let navigate = useNavigate();

  let location = useLocation();

  const [userName, setUserName] = useState<string>("");

  const [password, setPassword] = useState<string>("");

  const [showErrorTips, setErrorTips] = useState<boolean>(false);

  const [remember, setRemember] = useState<boolean>(rememberKey === "true");

  const tokenKey = (window as any).appsettings.tokenKey as string;

  const userNameKey = (window as any).appsettings.userNameKey as string;

  const [loading, setLoading] = useState<boolean>(false);

  const historyCallback = () => {
    let p = location.state as any;
    p?.from?.pathname
      ? navigate(
          p.from.pathname.includes("/digitalBroadcasting")
            ? "/home"
            : p.from.pathname,
          { replace: true }
        )
      : navigate("/home");
  };

  const handleClick = () => {
    !!userName &&
      !!password &&
      (function () {
        setLoading(true);

        PostLgoin({
          username: userName,
          password: password,
        })
          .then((token) => {
            if (token) {
              localStorage.setItem(tokenKey, token);
              localStorage.setItem(userNameKey, userName);
              signin(token, historyCallback);
            }
          })
          .catch(() => {
            setErrorTips(true);
          })
          .finally(() => setLoading(false));
      })();
  };

  const handleRemember = (isChecked: boolean) => {
    setRemember(() => {
      localStorage.setItem("rememberKey", String(isChecked));
      return isChecked;
    });
  };

  const onEnter = (keyCode: number) => {
    keyCode === 13 && !!userName && !!password && handleClick();
  };

  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    if (token) {
      signin(token, historyCallback);
    }
  }, []);

  useEffect(() => {
    setErrorTips(false);
  }, [userName, password]);

  return {
    setUserName,
    setPassword,
    handleClick,
    onEnter,
    showErrorTips,
    handleRemember,
    rememberKey,
    remember,
    loading,
  };
};
