import { useState } from "react";
import { PostAttachId, PostUrlImg } from "../../api/metashower";
import { IUploadModal, IUploadProps } from "./props";
import { App } from "antd";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { useDebounceFn } from "ahooks";

export const useAction = (props: IUploadModal) => {
  const { message } = App.useApp();
  const { pageType, setOpenModal, handleUpdateList } = props;
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [uplaodVal, setUploadVal] = useState<IUploadProps>({
    inputVal: "",
    uploadId: 0,
  });

  const handleUploadImg = (info: { file: string | Blob | RcFile }) => {
    const formData = new FormData();
    formData.append("file", info.file);
    const newfile: UploadFile[] = [];

    setLoading(true);
    PostUrlImg(formData)
      .then((res) => {
        if (res) {
          message.success(`上傳成功`);
          newfile.push({ url: res.fileUrl, uid: "", name: uplaodVal.inputVal });
          setFileList(newfile);
          setImageUrl(res.fileUrl);
          setUploadVal({
            ...uplaodVal,
            uploadId: res.id,
          });
        }
      })
      .catch(() => {
        message.error("上傳失敗,請重新上傳");
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = useDebounceFn(
    () => {
      if (fileList.length === 0) return message.error(`请先上传图片`);
      const data = {
        metaShowerGlobalMediaData: [
          (uplaodVal.inputVal ?? "") !== ""
            ? {
                attachmentIds: [uplaodVal.uploadId],
                mediaType: pageType,
                title: uplaodVal.inputVal,
                isPublic: isPublic,
              }
            : {
                attachmentIds: [uplaodVal.uploadId],
                mediaType: pageType,
                isPublic: isPublic,
              },
        ],
      };

      PostAttachId(data)
        .then((res) => {
          if (res) {
            setFileList([]);
            setIsPublic(false);
            setOpenModal(false);
            setImageUrl("");
            setLoading(false);
            handleUpdateList();
            setUploadVal({ inputVal: "", uploadId: 0 });
            message.success(`添加成功`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
    {
      wait: 500,
    }
  ).run;

  const handleCancel = () => {
    if (!loading) {
      setOpenModal(false);
      setFileList([]);
      setUploadVal({ inputVal: "", uploadId: 0 });
    } else {
      message.info("图片上传中，请稍等！");
    }
  };

  const beforeUpload = (file: RcFile): Promise<RcFile> => {
    return new Promise((resolve, reject) => {
      const maxSize = file.size / 1024 / 1024 > 20;
      if (maxSize) {
        message.error("请上传20M以内的图片！");
        reject(file);
      } else {
        setUploadVal({
          ...uplaodVal,
          inputVal: file.name,
        });

        resolve(file);
      }
    });
  };

  return {
    setUploadVal,
    loading,
    setLoading,
    setIsPublic,
    isPublic,
    handleCancel,
    fileList,
    handleSubmit,
    uplaodVal,
    beforeUpload,
    handleUploadImg,
    imageUrl,
  };
};
