import { IVoiceCloneRequest } from "../../dtos/voice-clone";
import { Post } from "../http-client";

export const PostVoiceCloneInference = async (data: {
  voiceClone: IVoiceCloneRequest;
}) => await Post<string>("/api/VoiceClone/inference", data);

export const PostVoiceCloneTrain = async (data: {
  voiceClone: IVoiceCloneRequest;
}) => await Post<string>("/api/VoiceClone/train", data);
