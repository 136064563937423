import {
  Table,
  Select,
  Input,
  Button,
  ConfigProvider,
  Space,
  Spin,
  Pagination,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { ButtonOperationsEnum } from "./props";
import useAction from "./hook";
import {
  MetaShowerBatch,
  MetaShowerBatchType,
  SearchType,
} from "../../../../dtos/metashower";
import { OperationType } from "../../../../dtos/recycle-bin";
import dayjs from "dayjs";
import Icon, {
  CloseOutlined,
  LeftOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./style.css";
import { noDataIcon } from "../../../../components/no-data";

const RecycleBin = () => {
  const {
    selectedRowKeys,
    recycleData,
    isLoading,
    pageParams,
    message,
    setSelectedRowKeys,
    handleOperate,
    setPageParams,
    reductionOrClear,
    loadData,
    navigate,
    searchType,
    setSearchType,
    keyword,
    setKeyword,
  } = useAction();

  const columns: ColumnsType<MetaShowerBatch> = [
    {
      title: (
        <div>
          <span>編號</span>
        </div>
      ),
      dataIndex: "id",
      align: "left",
      sorter: true,
      render: (_, record) => <div>{record.sequentialNumber ?? "-"}</div>,
    },
    {
      title: (
        <div>
          <span>名稱</span>
        </div>
      ),
      dataIndex: "title",
      align: "left",
      sorter: true,
    },
    {
      title: (
        <div>
          <span>內容時長</span>
        </div>
      ),
      dataIndex: "fileDurationString",
      align: "left",
      sorter: true,
      render: (text) => (text != null ? text : "-"),
    },
    {
      title: (
        <div>
          <span>體積</span>
        </div>
      ),
      dataIndex: "fileSize",
      align: "left",
      sorter: true,
      render: (text) => (text != null ? text + "MB" : "-"),
    },
    {
      title: (
        <div>
          <span>製作時間</span>
        </div>
      ),
      width: 150,
      dataIndex: "createdDate",
      align: "center",
      sorter: true,
      render: (text) => (text != null ? dayjs(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: (
        <div>
          <span>類型</span>
        </div>
      ),
      width: 100,
      dataIndex: "type",
      align: "center",
      sorter: true,
      render: (text) => (text === MetaShowerBatchType.Audio ? "音频" : "视频"),
    },
    {
      title: (
        <div>
          <span>操作</span>
        </div>
      ),
      width: 150,
      dataIndex: "operation",
      align: "center",
      render: (_, record) => (
        <div>
          <span
            className="text-[#4f428c] mr-[1rem] cursor-pointer"
            onClick={() =>
              handleOperate(ButtonOperationsEnum.Restore, () =>
                reductionOrClear([record.uuid], OperationType.Restore)
              )
            }
          >
            还原
          </span>
          <span
            className="text-[#f86b6b] cursor-pointer"
            onClick={() =>
              handleOperate(ButtonOperationsEnum.Clear, () =>
                reductionOrClear([record.uuid], OperationType.HardDelete)
              )
            }
          >
            清除
          </span>
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<MetaShowerBatch> = {
    columnTitle: "多選",
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: "60px",
  };

  return (
    <div className="w-full h-full pl-[3rem] pr-[15.64%] overflow-x-hidden no-scrollbar my-[1rem]">
      <div
        className={`w-full bg-white rounded-2xl box-border py-2 px-4 flex flex-col ${
          recycleData.count !== 0 ? "" : "h-full"
        }`}
      >
        <div className="flex items-start py-[0.75rem]">
          <div className="w-[4.75rem] text-center py-2 relative text-sm ml-[0.5rem] whitespace-nowrap">
            <LeftOutlined className="absolute left-0 top-3 text-xs" />
            <span
              className="font-medium cursor-pointer"
              onClick={() => navigate("/")}
            >
              返回
            </span>
          </div>
          <div className="lg:pl-10 md:pl-8 sm:pl-6">
            <Space.Compact>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#E9E9E9",
                    colorBorderBg: "#868686",
                  },
                }}
              >
                <Select
                  value={searchType}
                  className="w-24 text-center hover:!border-[#E9E9E9]"
                  popupClassName={"text-center "}
                  options={[
                    { value: SearchType.All, label: "全部" },
                    { value: SearchType.Number, label: "編號" },
                    { value: SearchType.Title, label: "名稱" },
                  ]}
                  onSelect={(value) => setSearchType(value)}
                />
              </ConfigProvider>
              <Input
                prefix={<SearchOutlined style={{ color: "#C2C5C8" }} />}
                suffix={
                  <CloseOutlined
                    className="cursor-pointer"
                    onClick={() => !!keyword && setKeyword("")}
                  />
                }
                className="lg:w-[20rem] md:w-72 sm:w-64 hover:!border-[#E9E9E9] !border-[#E9E9E9]"
                placeholder="Please Input"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onPressEnter={() => loadData()}
              />
              <Button
                className="hover:!border-[#E9E9E9] hover:!text-black"
                onClick={() => loadData()}
              >
                确定
              </Button>
            </Space.Compact>
          </div>
          <div className="flex space-x-4 space-x-reverse flex-1 flex-row-reverse">
            <Button
              className="bg-[#FF4E4E] text-white border-none"
              onClick={() => {
                handleOperate(ButtonOperationsEnum.ClearAll, () =>
                  reductionOrClear([], OperationType.AllDelete)
                );
              }}
            >
              清空
            </Button>
            <Button
              className="bg-[#4D7CEE] text-white border-none"
              onClick={() => {
                selectedRowKeys.length > 0
                  ? handleOperate(ButtonOperationsEnum.Restore, () =>
                      reductionOrClear(
                        selectedRowKeys as string[],
                        OperationType.Restore
                      )
                    )
                  : message.info("请先勾选还原需要的数据");
              }}
            >
              還原
            </Button>
          </div>
        </div>
        <div className="flex-1">
          <ConfigProvider
            theme={{
              token: { colorPrimary: "#4D7CEE" },
            }}
          >
            <Spin spinning={isLoading}>
              {recycleData.count !== 0 ? (
                <>
                  <Table
                    pagination={false}
                    rowSelection={rowSelection}
                    columns={columns}
                    scroll={{ x: 1000, y: 620 }}
                    dataSource={recycleData.metaShowerBatches}
                    rowKey={(record) => record.uuid}
                    locale={{
                      triggerDesc: "点击降序",
                      triggerAsc: "点击升序",
                      cancelSort: "取消排序",
                    }}
                    onChange={(_pagination, _filters, sorter: any) => {
                      loadData(
                        sorter.order === undefined ? "" : sorter.field,
                        sorter.order ?? ""
                      );
                    }}
                    onHeaderRow={() => {
                      return {
                        className: "recycling",
                      };
                    }}
                  />
                  <div className="flex justify-end items-center homePagination recyclingPagination py-[1.5rem]">
                    <Pagination
                      current={pageParams.pageIndex}
                      pageSize={pageParams.pageSize}
                      onChange={(page) => {
                        setPageParams((prev) => ({
                          ...prev,
                          pageIndex: page,
                        }));
                      }}
                      showSizeChanger={false}
                      total={recycleData.count}
                    />
                  </div>
                </>
              ) : (
                <div className="flex-1 flex items-start justify-center mt-[11.44rem] h-full">
                  <Icon component={noDataIcon} />
                </div>
              )}
            </Spin>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default RecycleBin;
