export interface DeleteOrRestoreRequest {
  metaShowerBatchUuids: string[];
  operationType: OperationType;
}

export enum OperationType {
  SoftDelete, // 视频删除到回收站
  Restore, // 回收站还原数据到源列表
  HardDelete, // 回收站删除batch
  AllDelete, //回收站全删除
}
