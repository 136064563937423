import { useDebounceFn } from "ahooks";
import { MetaShowerBatchType } from "../../../../dtos/metashower";

import {
  Proportions,
  ResolutionRatio,
  ResolutionRatioFullHD,
  ResolutionRatioHD,
  ResolutionRatioSD,
} from "../../../../dtos/digital-broadcasting";
import { useEffect, useState } from "react";

export const OutPutFormatValue = {
  [MetaShowerBatchType.OriginalVideo]: "視頻",
  [MetaShowerBatchType.Audio]: "音頻",
};

export const useAction = (props: {
  proportion?: string | number | boolean;
  generateVideo: () => void;
  setVideoResolutions: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { proportion, generateVideo, setVideoResolutions } = props;

  const outPutFormatOptions = [
    {
      value: MetaShowerBatchType.OriginalVideo,
      label: OutPutFormatValue[MetaShowerBatchType.OriginalVideo],
    },
    {
      value: MetaShowerBatchType.Audio,
      label: OutPutFormatValue[MetaShowerBatchType.Audio],
    },
  ];

  const videoOptions = [
    {
      value: ResolutionRatio.FullHD,
      label: "1080 P",
    },
    { value: ResolutionRatio.HD, label: "720 P" },
    { value: ResolutionRatio.SD, label: "480 P" },
  ];

  const [resolutionRatio, setResolutionRatio] = useState<ResolutionRatio>(
    ResolutionRatio.FullHD
  );

  const handelSetResolutionRatio = (resolutionRatio: ResolutionRatio) => {
    setResolutionRatio(resolutionRatio);
    switch (resolutionRatio) {
      case ResolutionRatio.FullHD:
        setVideoResolutions(ResolutionRatioFullHD[proportion as Proportions]);
        break;
      case ResolutionRatio.HD:
        setVideoResolutions(ResolutionRatioHD[proportion as Proportions]);
        break;
      case ResolutionRatio.SD:
        setVideoResolutions(ResolutionRatioSD[proportion as Proportions]);
        break;

      default:
        setVideoResolutions(ResolutionRatioFullHD[proportion as Proportions]);
        break;
    }
  };

  useEffect(() => {
    handelSetResolutionRatio(resolutionRatio);
  }, [proportion]);

  const { run } = useDebounceFn(generateVideo, {
    wait: 100,
  });

  return {
    outPutFormatOptions,
    videoOptions,
    run,
    resolutionRatio,
    handelSetResolutionRatio,
  };
};
