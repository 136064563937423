import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ConfigProvider, Slider } from "antd";
import { useLongPress } from "ahooks";
import { IIconSliderProps } from "./props";
import { useAction } from "./hook";

export const IconSlider = (props: IIconSliderProps) => {
  const { title, min, max, size, setSize } = props;

  const {
    decreaseIconRef,
    increaseIconRef,
    decreaseIntervalRef,
    increaseIntervalRef,
  } = useAction();

  const volumeIconStyle = "cursor-pointer transition-[300] text-[1rem]";

  const startDecrease = () => {
    decreaseIntervalRef.current = setInterval(
      () => setSize((prevSize) => (prevSize > min ? prevSize - 1 : prevSize)),
      50
    );
  };

  const stopDecrease = () => {
    clearInterval(decreaseIntervalRef.current);
  };

  const startIncrease = () => {
    increaseIntervalRef.current = setInterval(
      () => setSize((prevSize) => (prevSize < max ? prevSize + 1 : prevSize)),
      50
    );
  };

  const stopIncrease = () => {
    clearInterval(increaseIntervalRef.current);
  };

  useLongPress(startDecrease, decreaseIconRef, {
    onClick: () => size > min && setSize((prevSize) => prevSize - 1),
    onLongPressEnd: stopDecrease,
  });

  useLongPress(startIncrease, increaseIconRef, {
    onClick: () => size < max && setSize((prevSize) => prevSize + 1),
    onLongPressEnd: stopIncrease,
  });

  return (
    <div className="p-[1rem] border-solid border border-[#E9E9E9] mb-4 rounded-lg">
      <div className="mb-4 text-[1rem] text-[#0B1121] w-[2.5rem] text-center">
        {title}
      </div>
      <div className="flex items-center text-[1.25rem] text-[#666262]">
        <div className="hover:scale-[1.15] w-[2.5rem] h-[2.5rem] border-solid border border-[#E9E9E9] rounded-lg flex items-center justify-center">
          <MinusOutlined className={volumeIconStyle} ref={decreaseIconRef} />
        </div>
        <ConfigProvider
          theme={{
            components: {
              Slider: {
                trackBg: "#4D7CEE",
                handleSize: 0,
                railSize: 6,
                trackHoverBg: "#4D7CEE",
              },
            },
          }}
        >
          <Slider
            min={min}
            max={max}
            value={size}
            onChange={setSize}
            tooltip={{
              open: true,
              formatter: (value: number | undefined) => `${value}%`,
              getPopupContainer: (triggerNode) => triggerNode ?? document.body,
            }}
            className="flex-1 mx-[.75rem] soundSlider"
          />
        </ConfigProvider>
        <div className="hover:scale-[1.15] w-[2.5rem] h-[2.5rem] border-solid border border-[#E9E9E9] rounded-lg flex items-center justify-center">
          <PlusOutlined className={volumeIconStyle} ref={increaseIconRef} />
        </div>
      </div>
    </div>
  );
};
