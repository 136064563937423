import { Button, message } from "antd";
import TextArea from "antd/es/input/TextArea";

import { InputSide, useAction } from "./hook";

import metis from "../../../../assets/images/home/metis.png";
import user from "../../../../assets/images/home/user.png";
import { CopyOutlined } from "@ant-design/icons";

export const AIDialog = (props: { originalText: string }) => {
  const { originalText } = props;
  const {
    chatBoxRef,
    text,
    setText,
    clickGenerate,
    messages,
    generateLoading,
  } = useAction({ originalText });

  return (
    <div className="flex flex-1 h-full flex-col ">
      <div className="flex flex-col bg-white rounded-lg">
        <div
          ref={chatBoxRef}
          className="h-[26.2rem] flex flex-col overflow-y-auto no-scrollbar space-y-2 w-full box-border pt-[1.25rem] px-[1.25rem]"
        >
          <span className="font-mono text-base">文案预览:</span>

          {messages.map((item) => (
            <div
              key={item.uuid}
              className={`flex ${
                item.inputSide === InputSide.User &&
                "flex-row-reverse justify-end"
              }`}
            >
              {item.inputSide === InputSide.User ? (
                <img
                  src={user}
                  alt="User"
                  className="ml-[1rem]"
                  style={{ width: "2.5rem", height: "2.5rem" }}
                />
              ) : (
                <div>
                  <img
                    src={metis}
                    alt="metis"
                    className="mr-[1rem]"
                    style={{ width: "2.5rem", height: "2.5rem" }}
                  />
                  <div className="text-center cursor-pointer">
                    <CopyOutlined
                      onClick={() => {
                        const textarea = document.createElement("textarea");

                        textarea.value = item.content;

                        document.body.appendChild(textarea);

                        textarea.select();

                        document.execCommand("copy");

                        message.success("複製成功");

                        document.body.removeChild(textarea);
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                className={`${
                  item.inputSide === InputSide.User && "flex justify-end"
                }`}
                style={{ width: "calc(100% - 2.5rem)" }}
              >
                <div
                  className={`max-w-[70%] whitespace-pre-line pl-[.75rem] py-[.56rem] pr-[.56rem] rounded border border-solid text-sm ${
                    item.inputSide === InputSide.User
                      ? "bg-[#DBE5FC] border-[#DBE5FC]"
                      : "border-[#FAFBFF]"
                  }`}
                >
                  {item.content}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-1 mt-[.75rem]">
        <TextArea
          className="h-full w-full mr-[1rem] myCreateTextArea"
          style={{ resize: "none", backgroundColor: "white" }}
          bordered={false}
          placeholder="Please input..."
          value={text}
          allowClear
          showCount={{
            formatter: (info: {
              value: string;
              count: number;
              maxLength?: number;
            }) => {
              return (
                <span className="text-[#0B1121] text-[.75rem] absolute bottom-6 right-2">
                  {info.count}/{info.maxLength}
                </span>
              );
            },
          }}
          maxLength={100}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <Button
          className="w-[5.5rem] h-[2.5rem] ml-auto flex justify-center items-center"
          onClick={clickGenerate}
          loading={generateLoading}
          type="primary"
        >
          生成文案
        </Button>
      </div>
    </div>
  );
};
