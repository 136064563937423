import { useEffect, useState } from "react";

export const useAction = () => {
  const [isError, setIsError] = useState<boolean>(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [modalWidth, setModalWidth] = useState<number>(0);

  const [modalHetght, setModalHeight] = useState<number>(0);

  const playVideoError = () => {
    setIsError(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let width = "0";
    let height = "0";

    if (windowWidth > 2000) {
      width = ((windowWidth - 800) / 16).toFixed(4);
      height = (Number(width) * 0.5625).toFixed(4);
    } else if (windowWidth > 1500) {
      width = ((windowWidth - 400) / 16).toFixed(4);
      height = (Number(width) * 0.5625).toFixed(4);
    } else if (windowWidth > 1000) {
      width = ((windowWidth - 240) / 16).toFixed(4);
      height = (Number(width) * 0.5625).toFixed(4);
    } else if (windowWidth > 500) {
      width = ((windowWidth - 160) / 16).toFixed(4);
      height = (Number(width) * 0.5625).toFixed(4);
    } else {
      width = ((windowWidth - 120) / 16).toFixed(4);
      height = (Number(width) * 0.5625).toFixed(4);
    }

    setModalHeight(Number(height));
    setModalWidth(Number(width));
  }, [windowWidth]);

  return { isError, playVideoError, modalWidth, modalHetght, setIsError };
};
