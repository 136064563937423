import Pagination from "antd/es/pagination";
import { useAction } from "./hook";
import { MetaShowerType, UploadTitle } from "../../../../dtos/metashower";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
import { CardItemN } from "../card-item-new";
import { IAvatarProps } from "./props";
import Spin from "antd/es/spin";
import UploadModal from "../../../../components/upload-modal";
import { useContext } from "react";
import { DigitalSelectorContext } from "../digital-selector";

export const Avatar = (props: IAvatarProps) => {
  const { type, bgAndAvatar, updateBgAndAvatar } = props;

  const { avatarMediaId, backgroundMediaId } = useContext(
    DigitalSelectorContext
  );

  const {
    isLoading,
    setPageIndex,
    attachmentDto,
    mediasUrlList,
    pageIndex,
    isShowModalOpen,
    setIsShowModalOpen,
    getAttachment,
  } = useAction({
    mediaType: type,
  });

  const UploadCard = () => {
    return (
      <div
        className={`bg-[#F4F7FE] rounded-lg transition cursor-pointer flex justify-center items-center h-[11rem] flex-col`}
        onClick={() => {
          setIsShowModalOpen(true);
        }}
      >
        <PlusOutlined style={{ fontSize: 40, color: "#94B0F5" }} />
        <div className="text-[0.875rem] text-[#94B0F5] mt-2">
          支持JPG、PNG、MP4格式
        </div>
      </div>
    );
  };

  const NoSelectCard = () => {
    return (
      <div>
        <div
          className={`bg-[#f6f6f6] rounded-lg transition mx-1 cursor-pointer flex justify-center items-center ${
            type === MetaShowerType.Avatar ? "h-[13.5rem]" : "h-[11rem]"
          } ${
            (type === MetaShowerType.Avatar
              ? !avatarMediaId
              : !backgroundMediaId) &&
            "outline outline-offset-0 outline-1 outline-[#4D7CEE]"
          }`}
          onClick={() => updateBgAndAvatar(type, null)}
        >
          <StopOutlined style={{ fontSize: 33.33, color: "#a5a3a3" }} />
        </div>
        {type === MetaShowerType.Avatar && (
          <div className="text-center text-[#0B1121] mt-1">無数字人形象</div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className={`flex-1 grid ${
          type === MetaShowerType.Avatar ? "grid-cols-3" : "grid-cols-2"
        } gap-4 auto-rows-max relative`}
      >
        {isLoading ? (
          <Spin className="absolute top-1/2 left-1/2" />
        ) : (
          <>
            {type === MetaShowerType.Background && <UploadCard />}

            <NoSelectCard />

            {mediasUrlList.map((item, index) => (
              <CardItemN
                key={index}
                item={item}
                type={type}
                bgAndAvatar={bgAndAvatar}
                updateBgAndAvatar={updateBgAndAvatar}
              />
            ))}
          </>
        )}
      </div>
      <div className="flex justify-center items-center box-border pt-2">
        <Pagination
          total={attachmentDto.count}
          onChange={(page) => {
            setPageIndex(page);
          }}
          current={pageIndex}
          pageSize={type === MetaShowerType.Avatar ? 11 : 6}
          size={"small"}
          showSizeChanger={false}
        />
      </div>

      <UploadModal
        isOpenModal={isShowModalOpen}
        pageType={MetaShowerType.Background}
        modalTitle={UploadTitle.BackgroundTitle}
        setOpenModal={setIsShowModalOpen}
        handleUpdateList={getAttachment}
      />
    </div>
  );
};
