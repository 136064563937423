import { useState } from "react";
import { App } from "antd";
import {
  GenderTypeEnum,
  IVoiceCloneRequest,
  LanguageTypeEnum,
} from "../../dtos/voice-clone";
import {
  PostVoiceCloneInference,
  PostVoiceCloneTrain,
} from "../../api/voice-clone";
import { useDebounceFn } from "ahooks";

export const useAction = () => {
  const { message } = App.useApp();

  const [isReasoning, setIsReasoningData] = useState<boolean>(false);

  const [isTraning, setIsTraning] = useState<boolean>(false);

  const [reasonUrl, setReasonUrl] = useState<string>("");

  const [voiceCloneReasonData, setVoiceCloneReasonData] =
    useState<IVoiceCloneRequest>({
      genderType: GenderTypeEnum.Male,
      name: "",
      text: "",
      type: LanguageTypeEnum.EnCn,
    });

  const [voiceCloneTrainData, setVoiceCloneTrainData] =
    useState<IVoiceCloneRequest>({
      genderType: GenderTypeEnum.Male,
      name: "",
      dataset: "",
      type: LanguageTypeEnum.EnCn,
    });

  const updateVoiceCloneData = (
    key: keyof IVoiceCloneRequest,
    value: string | number,
    setter: React.Dispatch<React.SetStateAction<IVoiceCloneRequest>>
  ) => {
    setter((prevData: IVoiceCloneRequest) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleTraining = useDebounceFn(
    () => {
      if (Object.values(voiceCloneTrainData).some((value) => value === ""))
        return message.error("请输入训练的内容，相应内容不能为空");

      setIsTraning(true);
      PostVoiceCloneTrain({ voiceClone: voiceCloneTrainData })
        .then((res) => {
          res && message.success("训练完成！");
          setVoiceCloneTrainData((prevData: IVoiceCloneRequest) => ({
            ...prevData,
            genderType: GenderTypeEnum.Male,
            name: "",
            dataset: "",
            type: LanguageTypeEnum.EnCn,
          }));
        })
        .catch((err) => message.error(err))
        .finally(() => setIsTraning(false));
    },
    { wait: 500 }
  ).run;

  const handleReasoning = useDebounceFn(
    () => {
      if (Object.values(voiceCloneReasonData).some((value) => value === ""))
        return message.error("请输入推理的内容，相应内容不能为空");

      setIsReasoningData(true);
      PostVoiceCloneInference({ voiceClone: voiceCloneReasonData })
        .then((res) => {
          res && setReasonUrl(res);
          setVoiceCloneReasonData((prevData: IVoiceCloneRequest) => ({
            ...prevData,
            text: "",
            name: "",
            genderType: GenderTypeEnum.Male,
            type: LanguageTypeEnum.EnCn,
          }));
          message.success("推理完成！");
        })
        .catch((err) => message.error(err))
        .finally(() => setIsReasoningData(false));
    },
    { wait: 500 }
  ).run;

  return {
    isReasoning,
    isTraning,
    handleTraining,
    handleReasoning,
    reasonUrl,
    voiceCloneReasonData,
    voiceCloneTrainData,
    updateVoiceCloneData,
    setVoiceCloneTrainData,
    setVoiceCloneReasonData,
  };
};
