import { useEffect, useRef, useState } from "react";

import {
  Proportions,
  DraggableElementType,
  SettingDataType,
  ChangeType,
  ImageType,
} from "../../dtos/digital-broadcasting";
import { useMsgContext } from "../home/components/contexts/msgContext";
import {
  AddMetaShower,
  GetMediaFileData,
  GetOneMedia,
  PostUrlImg,
} from "../../api/metashower";
import { v4 as uuidV4 } from "uuid";
import { App } from "antd";
import Konva from "konva";
import {
  AddMetaShowerCommand,
  BackgroundAttachmentType,
  GetAttachUrl,
  GlobalMediasType,
  ImageIdType,
  MediaPositionsType,
  MetaShowerBatchType,
  MetaShowerMediaType,
  MetaShowerOperationType,
  MetaShowerType,
} from "../../dtos/metashower";
import { useNavigate, useParams } from "react-router-dom";
import { CursorParams } from "./props";
import { clone } from "ramda";
import { useDebounceFn } from "ahooks";
import { CurrentSsmlTag, PhonemeBlot, SsmlTag } from "./components/ssml";
import ReactQuill, { Quill } from "react-quill";
import { convertSsml } from "../../utlis/ssml-utils";

export const useAction = () => {
  const { id } = useParams();

  const [settingData, setSettingData] = useState<SettingDataType[]>([
    {
      title: "字幕",
      changeType: ChangeType.Switch,
      key: "captions",
      value: false,
    },
    {
      title: "静音",
      changeType: ChangeType.Switch,
      key: "mute",
      value: false,
    },
    {
      title: "比例",
      changeType: ChangeType.Select,
      options: [
        {
          label: Proportions.SixteenToNine,
          value: Proportions.SixteenToNine,
        },
        {
          label: Proportions.NineToSixteen,
          value: Proportions.NineToSixteen,
        },
        {
          label: Proportions.FourToThree,
          value: Proportions.FourToThree,
        },

        {
          label: Proportions.ThreeToFour,
          value: Proportions.ThreeToFour,
        },
      ],
      key: "proportion",
      value: Proportions.SixteenToNine,
    },
  ]);

  const [digitalBeingProjectName, setDigitalBeingProjectName] =
    useState<string>("");

  const [broadcastContent, setBroadcastContent] = useState<string>("");

  const [cursorParams, setCursorParams] = useState<CursorParams>({
    cursorStart: undefined,
    cursorEnd: undefined,
    selectedText: undefined,
  });

  const [textLengthValue, setTextLengthValue] =
    useState<string>(broadcastContent);

  const [isEditProjectName, setIsEditProjectName] = useState<boolean>(false);

  const [broadcastBackground, setBroadcastBackground] =
    useState<DraggableElementType>({
      imageUrl: "",
      xCoordinate: 0,
      yCoordinate: 0,
      width: 200,
      height: 112.5,
      mediaId: 0,
    });

  const [digitalHumanImage, setDigitalHumanImage] =
    useState<DraggableElementType>({
      imageUrl: "",
      xCoordinate: 0,
      yCoordinate: 0,
      width: 180,
      height: 120,
      mediaId: 0,
    });

  // const [captionsData, setCaptionsData] = useState<DraggableElementType>({
  //   text: "字幕字幕字幕字幕字幕",
  //   xCoordinate: 0,
  //   yCoordinate: 0,
  //   width: 130,
  //   height: 25,
  //   fontSize: 14,
  // });

  const [videoResolutions, setVideoResolutions] = useState<number[]>([
    1920, 1080,
  ]);

  const broadcastBackgroundRef = useRef<HTMLDivElement>(null);

  const captionsRef = useRef<HTMLDivElement>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const stageRef = useRef<Konva.Stage>(null);

  const [isShowModalOpen, setIsShowModalOpen] = useState<boolean>(false);

  const [language, setLanguage] = useState<number | null>(null);

  const [volumeSize, setVolumeSize] = useState<number>(50);

  const [toneSize, setToneSize] = useState<number>(50);

  const [speechRate, setSpeechRate] = useState<number>(0);

  const [metaShowerOperationType, setMetaShowerOperationType] =
    useState<MetaShowerOperationType>(MetaShowerOperationType.Save);

  const [editUuid, setEditUuid] = useState<string>("");

  const [generateVideoType, setGenerateVideoType] =
    useState<MetaShowerBatchType>(MetaShowerBatchType.OriginalVideo);

  const canvasContentRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);

  const [screenWidth, setScreenWidth] = useState(0);

  const [screenHeight, setScreenHeight] = useState(0);

  const [editorValue, setEditorValue] = useState<string>("");

  const quillRef = useRef<ReactQuill>(null);

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "currentSmml",
    "phoneme",
  ];
  const modules = {
    toolbar: [[{ phoneme: "", currentSmml: "" }]],
  };

  const handleChange = (value: string) => {
    setEditorValue(value);
  };

  useEffect(() => {
    const container = document.createElement("div");
    container.innerHTML = editorValue;

    const tagsWithAttributes = Array.from(
      container.querySelectorAll("[isSSMLDom]")
    ).map((tag) => ({
      type: tag.getAttribute("type"),
      length: tag.getAttribute("length"),
      index: tag.getAttribute("index"),
      value: tag.getAttribute("ph"),
      label: tag.getAttribute("label"),
    }));

    const content = quillRef.current?.getEditor().getContents();

    let str = "";

    let tagIndex = 0;

    if (content && content.ops) {
      content.ops.forEach((op, index) => {
        if (typeof op.insert === "string") {
          str += op.insert.replace(/\n/g, "");
        } else {
          const data = tagsWithAttributes[tagIndex];

          if (data) {
            switch (Number(data.type)) {
              case SsmlTag.Pause:
                str += '<break time="500ms"/>';
                break;
              case SsmlTag.Ventilation:
                str += '<break time="200ms"/>';
                break;
              case SsmlTag.Reading:
                data.label && (str = str.slice(0, -data.label.length));
                str += `<say-as interpret-as="ordinal">${data.label}</say-as>`;
                break;
              case SsmlTag.Digit:
                data.label && (str = str.slice(0, -data.label.length));
                str += `<say-as interpret-as="digits">${data.label}</say-as>`;
                break;
              case SsmlTag.ReadingNumbers:
                data.label && (str = str.slice(0, -data.label.length));
                str += `<say-as interpret-as="cardinal">${data.label}</say-as>`;
                break;
              case SsmlTag.PolyphonicCharacter:
                data.label && (str = str.slice(0, -data.label.length));
                str += `<phoneme alphabet="py" ph="${data.value}">${data.label}</phoneme>`;
                break;
              default:
                break;
            }

            tagIndex += 1;
          }
        }
      });

      setBroadcastContent(str);
    }
  }, [editorValue]);

  const navigate = useNavigate();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  window.addEventListener("resize", handleResize);

  const { run: setDimensionsDebounced } = useDebounceFn(
    () => {
      if (
        canvasContentRef.current?.offsetHeight &&
        canvasContentRef.current?.offsetWidth
      ) {
        const height = canvasContentRef.current.scrollHeight;
        const scale = proportion?.toString().split(":");
        const width =
          (height / Number(scale ? scale[1] : 0)) *
          Number(scale ? scale[0] : 0);

        setDimensions({
          width: width,
          height: height,
        });
      }
    },
    {
      wait: 30,
    }
  );

  useEffect(() => {
    const handleResize = () => {
      setDimensionsDebounced();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setDimensionsDebounced]);

  const { message } = App.useApp();

  const updateSettingData = (
    key: string,
    value: boolean | string | number,
    isShowData?: boolean
  ) => {
    if (key === "proportion" && !isShowData) {
      setDigitalHumanImage((prev) => ({
        ...prev,
        xCoordinate: 0,
        yCoordinate: 0,
      }));
      setBroadcastBackground((prev) => ({
        ...prev,
        xCoordinate: 0,
        yCoordinate: 0,
      }));
    }
    setSettingData((prev) => {
      return prev.map((item) => ({
        ...item,
        value: item.key === key ? (item.value = value) : item.value,
      }));
    });
  };

  const getCombinedImageId = async (data: DraggableElementType) => {
    let combinedImageAttachmentFileId: undefined | number;

    const stage = stageRef.current?.getStage();
    const canvas = document.createElement("canvas");
    const dpr = window.devicePixelRatio || 1;

    switch (proportion) {
      case Proportions.NineToSixteen:
        canvas.width = 1080 * dpr;
        canvas.height = 1920 * dpr;
        break;
      case Proportions.SixteenToNine:
        canvas.width = 1920 * dpr;
        canvas.height = 1080 * dpr;
        break;
      case Proportions.FourToThree:
        canvas.width = 1440 * dpr;
        canvas.height = 1080 * dpr;
        break;
      case Proportions.ThreeToFour:
        canvas.width = 1080 * dpr;
        canvas.height = 1440 * dpr;
        break;

      default:
        canvas.width = 1920 * dpr;
        canvas.height = 1080 * dpr;
        break;
    }

    const canvasWidthScale = canvas.width / (stage?.width() ?? 1);
    const canvasHeightScale = canvas.height / (stage?.height() ?? 1);

    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.width = data.width * canvasWidthScale;
    img.height = data.height * canvasHeightScale;

    const xCoordinate = data.xCoordinate * canvasWidthScale;
    const yCoordinate = data.yCoordinate * canvasHeightScale;

    ctx?.scale(dpr, dpr);

    img.src = data.imageUrl ?? "";
    img.setAttribute("crossOrigin", "Anonymous");
    img.src = data.imageUrl ?? "";

    await new Promise<void>((resolve) => {
      img.onload = (e) => {
        ctx && (ctx.fillStyle = "green");
        ctx?.fillRect(0, 0, canvas.width, canvas.height);

        ctx?.drawImage(img, xCoordinate, yCoordinate, img.width, img.height);

        const url = canvas.toDataURL("image/jpeg", 1);
        const base64ToFile = (data: string) => {
          let base = window.atob(data.substring(data.indexOf(",") + 1));
          let length = base.length;
          let url = new Uint8Array(length);
          while (length--) {
            url[length] = base.charCodeAt(length);
          }
          let file = new File([url], `${new Date().getTime()}.jpeg`, {
            type: "image/jpeg",
          });

          const formData = new FormData();
          formData.append("file", file);

          return formData;
        };

        const combinedImageAttachmentFile = base64ToFile(url);

        (async () => {
          const combinedImageData = await PostUrlImg(
            combinedImageAttachmentFile
          );

          combinedImageAttachmentFileId = combinedImageData?.id ?? 0;

          resolve();
        })();
      };
    });

    return combinedImageAttachmentFileId;
  };

  const getMaterialProcessingSize = (
    width: number,
    height: number,
    x: number,
    y: number,
    isToCanvas?: boolean,
    scale?: number[]
  ) => {
    const stage = stageRef.current?.getStage();

    let widthScale;
    let heightScale;

    const apiProportion = scale ? `${scale[0]}:${scale[1]}` : undefined;

    switch (apiProportion ?? proportion) {
      case Proportions.NineToSixteen:
        widthScale = 1080 / (stage?.width() ?? 1);
        heightScale = 1920 / (stage?.height() ?? 1);
        break;
      case Proportions.SixteenToNine:
        widthScale = 1920 / (stage?.width() ?? 1);
        heightScale = 1080 / (stage?.height() ?? 1);
        break;
      case Proportions.FourToThree:
        widthScale = 1440 / (stage?.width() ?? 1);
        heightScale = 1080 / (stage?.height() ?? 1);
        break;
      case Proportions.ThreeToFour:
        widthScale = 1080 / (stage?.width() ?? 1);
        heightScale = 1440 / (stage?.height() ?? 1);
        break;

      default:
        widthScale = 1920 / (stage?.width() ?? 1);
        heightScale = 1080 / (stage?.height() ?? 1);
        break;
    }

    return {
      width: isToCanvas ? width / widthScale : width * widthScale,
      height: isToCanvas ? height / heightScale : height * heightScale,
      x: isToCanvas ? x / widthScale : x * widthScale,
      y: isToCanvas ? y / heightScale : y * heightScale,
    };
  };

  const proportion = settingData.find(
    (item) => item.key === "proportion"
  )?.value;

  // 获取新建制作标题和文案
  const { title, context } = useMsgContext();

  useEffect(() => {
    if (title || context) {
      setDigitalBeingProjectName(title);
      setBroadcastContent(context);
      setEditorValue(context);
    }
  }, [title, context]);

  useEffect(() => {
    if (
      canvasContentRef.current?.offsetHeight &&
      canvasContentRef.current?.offsetWidth
    ) {
      const height = canvasContentRef.current.scrollHeight;
      const scale = proportion?.toString().split(":");
      const width =
        (height / Number(scale ? scale[1] : 0)) * Number(scale ? scale[0] : 0);
      setDimensions({
        width: width,
        height: height,
      });
    }
  }, [proportion]);

  //初始化定位虚拟人形象和字幕;
  useEffect(() => {
    if (canvasContentRef.current && (!id || id === "new")) {
      const bgWidth = canvasContentRef.current.offsetWidth;
      const scale = proportion?.toString().split(":");

      const bgHeight =
        (bgWidth / Number(scale ? scale[0] : 0)) * Number(scale ? scale[1] : 0);

      setBroadcastBackground((prev) => ({
        ...prev,
        width: bgWidth,
        height: bgHeight,
        xCoordinate: 0,
        yCoordinate: 0,
      }));
    }
  }, []);

  const isImageExtension = (extension: string) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
    return imageExtensions.includes(extension.toLowerCase());
  };

  const isVideoExtension = (extension: string) => {
    const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm"];
    return videoExtensions.includes(extension.toLowerCase());
  };

  const getBase64Data = (data: "" | GetAttachUrl | null) => {
    if (data) {
      const extension = data.fileName.split(".")[1];
      if (isImageExtension(extension)) {
        return `data:image/${
          extension === "svg" ? extension + "+xml" : extension
        };base64,${data.fileContent}`;
      } else if (isVideoExtension(extension)) {
        return `data:video/${extension};base64,${data.fileContent}`;
      }
    }
    return "";
  };

  const handleGetImageUrl = async (type: number, mediaId: number | null) => {
    const src = mediaId
      ? await GetMediaFileData({
          Id: mediaId,
          Type: ImageIdType.MediaId,
        })
      : "";

    const base64Data = getBase64Data(src);

    let imgWidth = 180;
    let imgHeight = 120;
    if (src) {
      const img = new Image();
      img.src = src.fileUrl;
      imgWidth = 200;

      imgHeight = img.height * (imgWidth / img.width);
    }

    type !== ImageType.ScreenBackground
      ? setDigitalHumanImage((prev) => ({
          ...prev,
          imageUrl: base64Data ?? "",
          mediaId,
          width: imgWidth,
          height: imgHeight,
        }))
      : setBroadcastBackground((prev) => ({
          ...prev,
          imageUrl: base64Data ?? "",
          mediaId,
          width: dimensions.width,
          height: dimensions.height,
        }));
  };

  const generateVideo = async () => {
    const stage = stageRef.current?.getStage();

    //清除所有控制器
    stage &&
      stage.find("Transformer").forEach((tr) => {
        tr.hide();
      });

    if (
      !language &&
      metaShowerOperationType === MetaShowerOperationType.Generate
    ) {
      return message.info("请确认语言");
    }

    if (digitalBeingProjectName === "" || broadcastContent === "") {
      return message.info("请确认标题和内容填写完整");
    }

    if (
      !digitalHumanImage.mediaId &&
      generateVideoType !== MetaShowerBatchType.Audio &&
      metaShowerOperationType !== MetaShowerOperationType.Save
    ) {
      return message.info("请确认虚拟形象选择");
    }

    let mediaPositions: MediaPositionsType[] = [];

    const processMedia = (
      media: DraggableElementType,
      positionFrom: MetaShowerMediaType
    ) => {
      if (media.mediaId) {
        const { width, height, x, y } = getMaterialProcessingSize(
          media.width,
          media.height,
          media.xCoordinate,
          media.yCoordinate
        );

        mediaPositions.push({
          positionFrom,
          coordinateX: Math.floor(x),
          coordinateY: Math.floor(y),
          width: Math.floor(width),
          height: Math.floor(height),
        });
      } else {
        mediaPositions.push({
          positionFrom,
          coordinateX: 0,
          coordinateY: 0,
          width: 0,
          height: 0,
        });
      }
    };

    processMedia(digitalHumanImage, MetaShowerMediaType.Avatar);
    processMedia(broadcastBackground, MetaShowerMediaType.Background);

    metaShowerOperationType === MetaShowerOperationType.Generate
      ? setIsGenerateLoading(true)
      : setIsSaveLoading(true);

    let combinedImageAttachmentId: number | undefined;

    if (
      digitalHumanImage.imageUrl &&
      digitalHumanImage.mediaId &&
      generateVideoType !== MetaShowerBatchType.Audio &&
      metaShowerOperationType === MetaShowerOperationType.Generate
    ) {
      const combinedImageAttachmentFileId = await getCombinedImageId(
        digitalHumanImage
      );

      combinedImageAttachmentId = combinedImageAttachmentFileId ?? 0;

      if (!combinedImageAttachmentId) return;
    }

    const mediaIds = digitalHumanImage.mediaId
      ? [digitalHumanImage.mediaId]
      : [];

    if (broadcastBackground.mediaId) {
      mediaIds.push(broadcastBackground.mediaId);
    }

    if (
      digitalHumanImage.mediaId &&
      !combinedImageAttachmentId &&
      generateVideoType !== MetaShowerBatchType.Audio &&
      metaShowerOperationType === MetaShowerOperationType.Generate
    ) {
      return message.info("上传数字人资料失败，请稍后尝试");
    }

    const data: AddMetaShowerCommand = {
      uuid: editUuid ? editUuid : uuidV4(),
      title: digitalBeingProjectName,
      text: getBroadcastContentBySpeakTags(broadcastContent)
        ? broadcastContent
        : "<speak>" + broadcastContent + "</speak>",
      language: language ?? 0,
      mediaIds: mediaIds ?? 0,
      combinedImageAttachmentId: combinedImageAttachmentId ?? 0,
      volume: settingData.find((x) => x.key === "mute")?.value ? 0 : volumeSize,
      speechRate: speechRate,
      pitchRate: toneSize,
      videoProportion: String(proportion)
        .split(":")
        .map((str) => parseFloat(str.trim())),
      videoResolutions: videoResolutions,
      type:
        broadcastBackground.mediaId &&
        generateVideoType !== MetaShowerBatchType.Audio
          ? 20
          : generateVideoType,
      mediaInsertMediaUrls: [""],
      metaShowerOperationType: metaShowerOperationType, //生成保存
      isPreviewAudioBatch: false,
      mediaPositions: mediaPositions,
      mediaInsertTimes: [],
      backgroundAttachmentType:
        getBackgroundAttachmentType(broadcastBackground) ??
        BackgroundAttachmentType.Image,
    };

    AddMetaShower(data)
      .then(() => {
        setIsShowModalOpen(false);

        message.success(
          `${
            metaShowerOperationType === MetaShowerOperationType.Generate
              ? "成功加入生成隊列，請等待生成"
              : "保存成功"
          }`
        );

        metaShowerOperationType === MetaShowerOperationType.Generate &&
          setTimeout(() => {
            navigate("/home");
          }, 1000);

        setEditUuid(
          metaShowerOperationType === MetaShowerOperationType.Generate
            ? ""
            : data.uuid
        );
      })
      .catch(() => message.error("服务器繁忙，请稍后再试"))
      .finally(() => {
        metaShowerOperationType === MetaShowerOperationType.Generate
          ? setIsGenerateLoading(false)
          : setIsSaveLoading(false);
      });
  };

  const { run: handelGenerateVideo } = useDebounceFn(generateVideo, {
    wait: 300,
  });

  const getBackgroundAttachmentType = (bgData: DraggableElementType) => {
    const match = bgData.imageUrl?.split(";")[0].split("/")[1];
    const extension = match ? match : "";

    return bgData.mediaId
      ? isImageExtension(extension)
        ? BackgroundAttachmentType.Image
        : isVideoExtension(extension)
        ? BackgroundAttachmentType.Video
        : null
      : null;
  };

  const getPreviewData = () => {
    const data: AddMetaShowerCommand = {
      uuid: uuidV4(),
      text: getBroadcastContentBySpeakTags(broadcastContent)
        ? broadcastContent
        : "<speak>" + broadcastContent + "</speak>",
      mediaIds: [],
      title: digitalBeingProjectName,
      combinedImageAttachmentId: 0,
      volume: volumeSize,
      speechRate: speechRate,
      pitchRate: toneSize,
      videoProportion: String(proportion)
        .split("/")
        .map((str) => parseFloat(str.trim())),
      videoResolutions: videoResolutions,
      language: language,
      mediaInsertTimes: [],
      mediaInsertMediaUrls: [],
      type: MetaShowerBatchType.Audio,
      metaShowerOperationType: MetaShowerOperationType.Generate,
      mediaPositions: [],
      isPreviewAudioBatch: true,
      backgroundAttachmentType: null,
    };

    return data;
  };

  const getBroadcastContentBySpeakTags = (broadcastContent: string) => {
    // 使用正则表达式检查字符串是否以<speak>开头且以</speak>结尾
    const regex = /^<speak>.*<\/speak>$/;
    return regex.test(broadcastContent);
  };

  const setImgToCanvas = (
    data: {
      base64: string | null;
      position?: MediaPositionsType;
      id?: number;
    },
    callback: (value: React.SetStateAction<DraggableElementType>) => void,
    proportion: number[]
  ) => {
    if (data.base64 && data.position && data.id) {
      const { width, height, x, y } = getMaterialProcessingSize(
        data.position.width,
        data.position.height,
        data.position.coordinateX,
        data.position.coordinateY,
        true,
        proportion
      );

      callback({
        imageUrl: data.base64,
        width,
        height,
        xCoordinate: x,
        mediaId: data.id,
        yCoordinate: y,
      });
    } else {
      callback({
        imageUrl: "",
        width: 200,
        height: 112.5,
        xCoordinate: 0,
        mediaId: 0,
        yCoordinate: 0,
      });
    }
  };

  const getMetaShowerTypeData = async (
    type: MetaShowerType,
    metaData?: GlobalMediasType[]
  ) => {
    const Id = metaData?.find((item) => item.mediaType === type)?.id;

    const fileData = Id
      ? await GetMediaFileData({
          Id,
          Type: ImageIdType.MediaId,
        })
      : null;

    const base64 = fileData && getBase64Data(fileData);

    return { id: Id, base64 };
  };

  useEffect(() => {
    const regex = /<[^>]*>/g;

    const cloneBroadcastContent = clone(broadcastContent);

    const result = cloneBroadcastContent.replace(regex, "");

    setTextLengthValue(() => result);
  }, [broadcastContent]);

  useEffect(() => {
    if (id !== "new" && !!id && stageRef.current) {
      (async () => {
        const data = await GetOneMedia(Number(id));

        if (data) {
          const {
            mediaPosition,
            volume,
            uuid,
            type,
            text,
            title,
            speechRate,
            resolution,
            proportion,
            pitchRate,
            language,
            metaShowerGlobalMedias,
          } = data;

          if (
            canvasContentRef.current?.offsetHeight &&
            canvasContentRef.current?.offsetWidth
          ) {
            const height = canvasContentRef.current.scrollHeight;

            const width =
              (height / Number(proportion ? proportion[1] : 0)) *
              Number(proportion ? proportion[0] : 0);

            setDimensions({
              width: width,
              height: height,
            });
          }

          const humanImageData = await getMetaShowerTypeData(
            MetaShowerType.Avatar,
            metaShowerGlobalMedias
          );
          const backgroundImageData = await getMetaShowerTypeData(
            MetaShowerType.Background,
            metaShowerGlobalMedias
          );

          const avatarMediaPosition = mediaPosition.find(
            (item) => item.positionFrom === MetaShowerMediaType.Avatar
          );

          const backgroundMediaPosition = mediaPosition.find(
            (item) => item.positionFrom === MetaShowerMediaType.Background
          );

          setImgToCanvas(
            {
              base64: humanImageData.base64,
              id: humanImageData.id,
              position: avatarMediaPosition,
            },
            setDigitalHumanImage,
            proportion
          );

          setImgToCanvas(
            {
              base64: backgroundImageData.base64,
              id: backgroundImageData.id,
              position: backgroundMediaPosition,
            },
            setBroadcastBackground,
            proportion
          );
          setEditorValue(text);

          setVolumeSize(volume);
          setToneSize(pitchRate);
          setSpeechRate(speechRate);
          setDigitalBeingProjectName(title);
          setGenerateVideoType(type);
          setBroadcastContent(text);
          setVideoResolutions(resolution);
          updateSettingData(
            "proportion",
            `${proportion[0]}:${proportion[1]}`,
            true
          );
          setEditUuid(uuid);
          setTimeout(() => {
            setLanguage(language);
          }, 500);

          if (quillRef && quillRef.current) {
            const quill = quillRef.current.getEditor();

            const ssmlTagList = [
              SsmlTag.Pause,
              SsmlTag.Ventilation,
              SsmlTag.Continuous,
              SsmlTag.Digit,
              SsmlTag.Pause,
              SsmlTag.ReadingNumbers,
              SsmlTag.Reading,
              SsmlTag.PolyphonicCharacter,
            ];

            const html = convertSsml(ssmlTagList, text);
            quill.root.innerHTML = html;
          }
        }
      })();
    }
  }, [id]);

  const [selection, setSelection] = useState({ index: 0, length: 0, text: "" });
  const [copy, setCopy] = useState<number>(0);

  const handelPaste = (event: {
    clipboardData: any;
    preventDefault: () => void;
  }) => {
    navigator.clipboard.readText().then((clipText) => {
      setSelection((prev) => ({ ...prev, text: clipText }));
      setCopy((prev) => prev + 1);
    });

    event.preventDefault();
    return;
  };

  useEffect(() => {
    Quill.register(CurrentSsmlTag, true);
    Quill.register(PhonemeBlot, true);

    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.root.addEventListener("paste", handelPaste);
    }
  }, []);

  useEffect(() => {
    if (selection.text && copy > 0) {
      const quill = quillRef.current?.getEditor();
      if ((quill?.getLength() ?? 0) + selection.text.length > 4000) {
        message.info("字数超出最大限制！");
        return;
      }
      if (quill) {
        quill.insertText(selection.index, selection.text);
      }
    }
  }, [copy]);

  return {
    settingData,
    broadcastBackground,
    digitalHumanImage,
    // captionsData,
    digitalBeingProjectName,
    isEditProjectName,
    broadcastBackgroundRef,
    captionsRef,
    broadcastContent,
    isShowModalOpen,
    proportion,
    canvasRef,
    stageRef,
    cursorParams,
    textLengthValue,
    volumeSize,
    toneSize,
    language,
    metaShowerOperationType,
    speechRate,
    generateVideoType,
    dimensions,
    canvasContentRef,
    isGenerateLoading,
    isSaveLoading,
    editorValue,
    quillRef,
    formats,
    modules,
    handleChange,
    navigate,
    setGenerateVideoType,
    setSpeechRate,
    setMetaShowerOperationType,
    setVolumeSize,
    setToneSize,
    handleGetImageUrl,
    setIsShowModalOpen,
    setBroadcastBackground,
    setBroadcastContent,
    setDigitalHumanImage,
    setIsEditProjectName,
    setDigitalBeingProjectName,
    updateSettingData,
    setLanguage,
    generateVideo,
    setVideoResolutions,
    setCursorParams,
    getPreviewData,
    handelGenerateVideo,
    setSelection,
  };
};
