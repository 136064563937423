import { Button, ConfigProvider, Dropdown, Modal } from "antd";
import { IAvatarProps } from "./props";
import { FileType, useAction } from "./hook";
import dayjs from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import "./styles.css";

export const Avatar = (props: IAvatarProps) => {
  const { item } = props;

  const {
    items,
    showDel,
    handleOk,
    handleCancel,
    fileType,
    stageRef,
    isDelLoading,
  } = useAction(props);

  return (
    <div className="flex justify-center items-center m-[0_1rem_1.5rem_0] box-border rounded-xl overflow-hidden bg-white">
      <div className="flex flex-col flex-1 h-full">
        <div className="w-full h-[17.75rem]">
          {fileType === FileType.Image ? (
            <img
              src={item.fileUrl}
              className="w-full h-full object-cover"
              alt=""
            />
          ) : (
            <video className="w-full h-full object-fill">
              <source src={item.fileUrl} />
            </video>
          )}
        </div>
        <div className="w-full flex-1 box-border px-4 pt-5 pb-6 flex">
          <div className=" flex flex-col flex-1 space-y-4">
            <div className="flex flex-row">
              <div className="flex-1 flex items-center">
                <div className="w-28 truncate text-base font-normal">
                  {item.title}
                </div>
              </div>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#EDF2FD",
                    controlItemBgHover: "#EDF2FD",
                    colorPrimaryHover: "#fff",
                    colorBgContainer: "#4D7CEE",
                  },
                }}
              >
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <Button className="bg-[#4D7CEE] text-white text-sm font-medium">
                    <span>更多</span>
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </ConfigProvider>
            </div>
            <div className="flex-1 space-y-2">
              <div className="flex text-base">
                <div>編號：</div>
                <div className="flex-1 truncate">
                  {item.sequentialNumber ?? "——————"}
                </div>
              </div>
              <div className="flex text-sm">
                <div>生成時間：</div>
                <div className="truncate flex-1">
                  {dayjs(item.createdDate).format("YYYY-MM-DD HH:mm:ss") ??
                    "——————"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <canvas ref={stageRef} style={{ display: "none" }} />
      <ConfigProvider theme={{ token: { colorPrimary: "#4D7CEE" } }}>
        <Modal
          title="操作"
          open={showDel}
          onOk={handleOk}
          confirmLoading={isDelLoading}
          onCancel={handleCancel}
          okText="确认"
          cancelText="取消"
        >
          <div className="mt-4">请确认是否删除当前图像?</div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
