import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAction = () => {
  const navigate = useNavigate();

  const [inputTitle, setInputTitle] = useState<string>("");

  const [inputContext, setInputContext] = useState<string>("");

  return {
    inputTitle,
    inputContext,
    navigate,
    setInputTitle,
    setInputContext,
  };
};
