import { useState, useRef, useEffect } from "react";
import { App } from "antd";
import { MetisGenerateContent } from "../../../../api/metis";
import { v4 as uuidV4 } from "uuid";

export interface IMessageItem {
  inputSide: InputSide;
  content: string;
  uuid: string;
}

export enum InputSide {
  Metis,
  User,
}

export const useAction = (props: { originalText: string }) => {
  const { originalText } = props;

  const [inputValue, setInputValue] = useState<string>("");

  const { message } = App.useApp();

  const chatBoxRef = useRef<HTMLDivElement>(null);

  const [text, setText] = useState<string>("");

  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const [messages, setMessages] = useState<IMessageItem[]>([]);

  const clickGenerate = () => {
    if (text) {
      updatedMessages(InputSide.User, text);

      setGenerateLoading(true);

      MetisGenerateContent({ InputContent: text })
        .then((res) => {
          if (res) {
            updatedMessages(InputSide.Metis, res);
            setGenerateLoading(false);
          }
        })
        .catch((err) => {
          message.error((err as Error).message);
          setGenerateLoading(false);
        });

      setText("");
    }
  };

  const updatedMessages = (inputSide: InputSide, content: string) => {
    setMessages((prev) => {
      const updatedMessages = [
        ...prev,
        {
          inputSide: inputSide,
          content: content,
          uuid: uuidV4(),
        },
      ];

      return updatedMessages;
    });
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  return {
    chatBoxRef,
    text,
    setText,
    clickGenerate,
    messages,
    generateLoading,
  };
};
