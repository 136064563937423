import { Button, ConfigProvider, Input, Select } from "antd";
import Switch from "../../components/switch";
import { ChangeType, ImageType } from "../../dtos/digital-broadcasting";
import { DigitalSelector } from "./components/digital-selector";
import { ProductionModal } from "./components/production-modal";
import { EditIcon, SelectIcon } from "./components/svg-icon";
import { useAction } from "./hook";
import { createContext } from "react";
import { Layer, Rect, Stage } from "react-konva";
import { DigitalBroadcastingContextProps } from "./props";
import { MetaShowerOperationType } from "../../dtos/metashower";
import EditableNode from "./components/editable-node";
import { LeftOutlined } from "@ant-design/icons";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const DigitalBroadcastingContext =
  createContext<DigitalBroadcastingContextProps>(null!);

const DigitalBroadcasting = () => {
  const {
    settingData,
    broadcastBackground,
    digitalHumanImage,
    // captionsData,
    digitalBeingProjectName,
    isEditProjectName,
    broadcastContent,
    isShowModalOpen,
    dimensions,
    stageRef,
    cursorParams,
    volumeSize,
    toneSize,
    language,
    metaShowerOperationType,
    speechRate,
    generateVideoType,
    canvasContentRef,
    isGenerateLoading,
    isSaveLoading,
    proportion,
    editorValue,
    quillRef,
    formats,
    modules,
    handleChange,
    navigate,
    setGenerateVideoType,
    setVolumeSize,
    setSpeechRate,
    setMetaShowerOperationType,
    setToneSize,
    handleGetImageUrl,
    setIsShowModalOpen,
    setBroadcastContent,
    setDigitalHumanImage,
    setIsEditProjectName,
    setDigitalBeingProjectName,
    // setCaptionsData,
    updateSettingData,
    setLanguage,
    setBroadcastBackground,
    setVideoResolutions,
    setCursorParams,
    getPreviewData,
    handelGenerateVideo,
    setSelection,
  } = useAction();

  return (
    <>
      <ProductionModal
        isShowModalOpen={isShowModalOpen}
        setIsShowModalOpen={setIsShowModalOpen}
        generateVideo={handelGenerateVideo}
        setVideoResolutions={setVideoResolutions}
        proportion={proportion}
        metaShowerOperationType={metaShowerOperationType}
        generateVideoType={generateVideoType}
        setGenerateVideoType={setGenerateVideoType}
        isLoading={isGenerateLoading}
      />

      <div className="p-4 h-full overflow-hidden max-sm:overflow-y-auto bg-[#f4f4f4] flex flex-col">
        <div className="flex items-center p-3 rounded-xl bg-white mb-3 justify-between">
          <div onClick={() => navigate("/home")} className="cursor-pointer">
            <LeftOutlined className="text-[#323232] mr-1 align-text-bottom" />
            <span className="text-[#0B1121] text-[0.875rem]">返回</span>
          </div>
          <div className="flex items-center w-9/12 justify-center">
            {isEditProjectName ? (
              <Input
                placeholder="数字人项目名称"
                style={{ width: "12rem" }}
                value={digitalBeingProjectName}
                onChange={(e) => setDigitalBeingProjectName(e.target.value)}
              />
            ) : (
              <div
                className="truncate max-w-2/3"
                title={digitalBeingProjectName}
              >
                {digitalBeingProjectName}
              </div>
            )}
            <div
              onClick={() => setIsEditProjectName((prev) => !prev)}
              className="flex ml-3 cursor-pointer"
            >
              <EditIcon />
            </div>
          </div>
          <div className="flex">
            <Button
              type="primary"
              className="mr-4 font-medium bg-[#4D7CEE] w-20"
              loading={isSaveLoading}
              onClick={() => {
                setMetaShowerOperationType(MetaShowerOperationType.Save);
                handelGenerateVideo();
              }}
            >
              保存
            </Button>

            <ConfigProvider theme={{ token: { colorPrimary: "#FF935E" } }}>
              <Button
                type="primary"
                className="mr-4 font-medium bg-[#FF935E] w-20"
                loading={isGenerateLoading}
                onClick={() => {
                  setMetaShowerOperationType(MetaShowerOperationType.Generate);
                  setIsShowModalOpen(true);
                }}
              >
                生成
              </Button>
            </ConfigProvider>
          </div>
        </div>
        <div className="flex flex-auto w-full max-h-[90%] max-sm:flex-wrap">
          <div className="px-[1rem] w-[70%] h-full rounded-md bg-white py-[1rem] shadow max-sm:w-full">
            <div
              className="flex justify-center h-3/5 w-full overflow-x-hidden bg-slate-200"
              ref={canvasContentRef}
            >
              <Stage
                ref={stageRef}
                width={dimensions.width}
                height={dimensions.height}
                className="videoCanvas flex justify-center"
              >
                <Layer>
                  <Rect
                    fill="black"
                    width={dimensions.width}
                    height={dimensions.height}
                  />

                  <EditableNode
                    x={broadcastBackground.xCoordinate}
                    y={broadcastBackground.yCoordinate}
                    width={broadcastBackground.width}
                    height={broadcastBackground.height}
                    src={broadcastBackground.imageUrl}
                    handleUpdateData={setBroadcastBackground}
                    stageRef={stageRef}
                    type={ImageType.ScreenBackground}
                  />
                  <EditableNode
                    x={digitalHumanImage.xCoordinate}
                    y={digitalHumanImage.yCoordinate}
                    width={digitalHumanImage.width}
                    height={digitalHumanImage.height}
                    stageRef={stageRef}
                    src={digitalHumanImage.imageUrl}
                    handleUpdateData={setDigitalHumanImage}
                    type={ImageType.DigitalImage}
                  />
                  {/* {false && (
                  <EditableNode
                    x={captionsData.xCoordinate}
                    y={captionsData.yCoordinate}
                    width={captionsData.width}
                    stageRef={stageRef}
                    height={captionsData.height}
                    text={captionsData.text}
                    fontSize={captionsData.fontSize}
                    handleUpdateData={setCaptionsData}
                    type={ImageType.Captions}
                  />
                )} */}
                </Layer>
              </Stage>
            </div>

            <div className="h-2/5 flex flex-col">
              <div className="flex items-center py-[1rem]">
                {settingData.map((item) => {
                  return (
                    <div key={item.key} className="flex items-center mr-[1rem]">
                      <span className="mr-3 whitespace-pre text-[#000000]">
                        {item.title}
                      </span>
                      {item.changeType === ChangeType.Switch && (
                        <Switch
                          checked={item.value as boolean}
                          onChange={(
                            modelChecked: React.ChangeEvent<Element>
                          ) => {
                            updateSettingData(item.key, !!modelChecked);
                          }}
                        />
                      )}
                      {item.changeType === ChangeType.Select && (
                        <Select
                          value={item.value}
                          className="videoSettingsSelect"
                          popupClassName="text-center"
                          options={item.options}
                          suffixIcon={<SelectIcon />}
                          onChange={(value) =>
                            updateSettingData(item.key, value)
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="relative flex-auto overflow-hidden">
                <ReactQuill
                  theme="snow"
                  onChange={(value, delta, source, editor) => {
                    if (editor.getLength() > 4000) return;
                    handleChange(value);
                  }}
                  value={editorValue}
                  onChangeSelection={(e) => {
                    setSelection((prev) => ({
                      ...prev,
                      index: e?.index ?? 0,
                      length: e?.length ?? 0,
                    }));
                  }}
                  className="broadcastQuill h-[99%]"
                  modules={modules}
                  formats={formats}
                  ref={quillRef}
                  style={{ whiteSpace: "pre-line" }}
                />

                <div className="text-[#1F1F39] text-[0.75rem] absolute right-[1rem] bottom-[.5rem]">
                  字数：
                  {quillRef.current?.getEditor().getLength()
                    ? quillRef.current?.getEditor().getLength() - 1
                    : 0}{" "}
                  / 4000
                </div>
              </div>
            </div>
          </div>
          <div className="pl-[1rem] w-[30%] h-full max-sm:w-full max-sm:pl-0 max-sm:mt-4">
            <DigitalBroadcastingContext.Provider
              value={{
                setLanguage,
                volumeSize,
                setVolumeSize,
                toneSize,
                setToneSize,
                setBroadcastContent,
                speechRate,
                setSpeechRate,
                broadcastContent,
                cursorParams,
                language,
                quillRef,
                setCursorParams,
              }}
            >
              <DigitalSelector
                handleGetImageUrl={handleGetImageUrl}
                getPreviewData={getPreviewData}
                avatarMediaId={digitalHumanImage.mediaId}
                backgroundMediaId={broadcastBackground.mediaId}
              />
            </DigitalBroadcastingContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalBroadcasting;
