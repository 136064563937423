import ReactPlayer from "react-player";
import { useAction } from "./hook";
import { ICardItemN } from "./props";
import { FileType } from "../../../../components/avatar/hook";
import { MetaShowerType } from "../../../../dtos/metashower";
import { useContext } from "react";
import { DigitalSelectorContext } from "../digital-selector";

export const CardItemN = (props: ICardItemN) => {
  const { item, type, updateBgAndAvatar } = props;

  const { avatarMediaId, backgroundMediaId } = useContext(
    DigitalSelectorContext
  );

  const { fileType } = useAction({ fileUrl: item.fileUrl });

  return (
    <div
      className="flex flex-col  cursor-pointer relative items-center"
      onClick={() => {
        item?.mediaId && updateBgAndAvatar(type, item?.mediaId);
      }}
    >
      <div
        className={`w-[96%] ${
          type === MetaShowerType.Avatar ? "h-[13.5rem]" : "h-[11rem]"
        } mx-1 bg-gray-100 rounded-md overflow-hidden ${
          (type === MetaShowerType.Avatar
            ? item.mediaId === avatarMediaId
            : item.mediaId === backgroundMediaId) &&
          "outline outline-offset-0 outline-1 outline-[#4D7CEE]"
        }`}
      >
        {fileType === FileType.Image ? (
          <img
            src={item.fileUrl}
            className="w-full h-full object-cover"
            alt="图片显示失败，请重试！"
          />
        ) : (
          <ReactPlayer width={"100%"} height={"100%"} url={item.fileUrl} />
        )}
      </div>
      {type === MetaShowerType.Avatar && (
        <div className="w-full truncate box-border px-1 text-center text-[#0B1121] mt-1">
          {item.title}
        </div>
      )}
    </div>
  );
};
