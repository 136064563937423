import React, { createContext, useContext, useState } from "react";

interface MsgContextValue {
  title: string;
  context: string;
  setTitle: (title: string) => void;
  setContext: (context: string) => void;
}

const MsgContext = createContext<MsgContextValue | null>(null);

export const useMsgContext = () => {
  const context = useContext(MsgContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};

export const MsgContextProvider = (props: { children: React.ReactNode }) => {
  const [title, setTitle] = useState<string>("");
  const [context, setContext] = useState<string>("");

  const contextValue: MsgContextValue = {
    title,
    context,
    setTitle,
    setContext,
  };

  return <MsgContext.Provider value={contextValue} {...props} />;
};
