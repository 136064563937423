import { Button, Checkbox, ConfigProvider, Input } from "antd";
import { useAction } from "./hook";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import loginBackground from "../../assets/login-bg.png";

const Login = () => {
  const {
    setUserName,
    setPassword,
    handleClick,
    onEnter,
    showErrorTips,
    handleRemember,
    rememberKey,
    remember,
    loading,
  } = useAction();

  return (
    <>
      <style>{`
        @media (max-height: 850px) {
          html {
            font-size: 1.7777778vh;
            height: 100%;
            position: fixed;
            width: 100%;
          }
        }
      
        @media (min-height: 851px) {
          html {
            font-size: 1em;
          }
        }
      
        @media (max-width: 1920px) {
          html {
            font-size: 0.8351vw;
          }
        }
      `}</style>
      <div className="flex flex-col items-center justify-center bg-[#F4F7FE] h-screen w-full relative overflow-y-auto">
        <div className="flex flex-col items-center justify-center w-[50rem] absolute">
          <div className="relative -top-[14.5rem]">
            <img
              src={loginBackground}
              alt="Login Background"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center justify-center relative">
          <div className="flex flex-col items-center rounded-2xl bg-white shadow-[0_-.25rem_.625rem_rgba(150,154,181,0.2)] w-[25rem] h-[23.3125rem]">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#475AEC",
                },
                components: {
                  Input: {
                    activeBorderColor: "#1777FF",
                  },
                },
              }}
            >
              <div className="z-20 flex flex-col w-[20rem]">
                <div
                  className={`cursor-pointer text-3xl font-medium text-blue-500 mt-[2rem] mx-auto`}
                >
                  Sign in
                </div>
                <Input
                  className="border-solid border-[.0625rem] border-[#E9E9E9] rounded-[0.5rem] h-[2.3rem] mt-[2.5rem] text-sm"
                  placeholder={"UserName"}
                  type="text"
                  onChange={(e) => setUserName(e.target.value)}
                  onKeyUp={(e) => onEnter(e.keyCode)}
                />
                <Input
                  className="password border-solid border-[.0625rem] border-[#E9E9E9] rounded-[0.5rem] h-[2.3rem] mt-[1.5rem] text-sm"
                  placeholder={"Password"}
                  type={`${rememberKey === "true" ? "password" : "text"}`}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyUp={(e) => onEnter(e.keyCode)}
                  status={showErrorTips ? "error" : undefined}
                  onCopy={(e) => {
                    e.preventDefault();
                  }}
                />
                {showErrorTips && (
                  <div className="text-[#FF4E4E] text-xs mt-1 font-normal">
                    密碼有誤，請重新輸入正確密碼
                  </div>
                )}
                <Checkbox
                  checked={remember}
                  className={`text-sm items-center self-start ${
                    showErrorTips ? "mt-[0.5rem]" : "mt-[1.5rem]"
                  }`}
                  onChange={(e: CheckboxChangeEvent) => {
                    handleRemember(e.target.checked);
                  }}
                >
                  記住密碼
                </Checkbox>
                <Button
                  className={`flex bg-[#4D7CEE] rounded-[.5rem] justify-center items-center text-white text-base font-medium cursor-pointer h-[2.8rem] ${
                    showErrorTips ? "mt-[2.75rem]" : "mt-[3rem]"
                  }`}
                  onClick={handleClick}
                  loading={loading}
                >
                  SIGN IN
                </Button>
              </div>
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
