import { Image, Text, Transformer } from "react-konva";
import { EditableNodeProps } from "./props";
import useAction from "./hook";

const EditableNode = (props: EditableNodeProps) => {
  const { src, text, fontSize, width, height, x, y } = props;

  const {
    isSelected,
    transformerRef,
    image,
    nodeX,
    nodeY,
    rotation,
    imageRef,
    textRef,
    handleDragStart,
    handleDragEnd,
    handleResizeStart,
    handleResizeEnd,
    setIsSelected,
    handleRotateKeyDown,
  } = useAction(props);

  return (
    <>
      {src ? (
        <Image
          image={image}
          x={x}
          y={y}
          width={width}
          height={height}
          draggable
          rotation={rotation}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onTransformStart={handleResizeStart}
          onTransformEnd={handleResizeEnd}
          ref={imageRef}
          onClick={(e) => {
            setIsSelected(!isSelected);
            transformerRef.current?.nodes([e.target]);
          }}
          tabIndex={0}
          onKeyDown={handleRotateKeyDown}
        />
      ) : (
        text && (
          <Text
            text={text}
            x={nodeX}
            y={nodeY}
            fontSize={fontSize}
            fill="white"
            draggable
            rotation={rotation}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onTransformStart={handleResizeStart}
            onTransformEnd={handleResizeEnd}
            ref={textRef}
            onClick={(e) => {
              setIsSelected(!isSelected);
              transformerRef.current?.nodes([e.target]);
            }}
          />
        )
      )}
      {isSelected && (
        <Transformer
          ref={transformerRef}
          anchorSize={12}
          rotateEnabled={false}
          borderStroke="#fff"
          anchorStroke="#fff"
          anchorCornerRadius={50}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-left",
            "bottom-right",
          ]}
        />
      )}
    </>
  );
};

export default EditableNode;
