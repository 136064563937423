export interface IPopupModaProps {
  keyword: string;
  config?: Object;
  callback: () => void;
}

export enum SelectOptionsEnum {
  All = 0,
  ID = 1,
  Title = 2,
  Type = 3,
}

export enum ButtonOperationsEnum {
  Restore = 0,
  ClearAll = 1,
  Clear = 2,
}
