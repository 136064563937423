import { App, Modal } from "antd";
import { IPopupModaProps, ButtonOperationsEnum } from "./props";
import { useEffect, useState } from "react";
import { GetListData } from "../../../../api/metashower";
import {
  DataType,
  MetaShowerBatchDto,
  SearchType,
} from "../../../../dtos/metashower";
import { DeleteOrRestoreMedia } from "../../../../api/recycle-bin";
import { OperationType } from "../../../../dtos/recycle-bin";
import { useNavigate } from "react-router-dom";
import { useDebounceFn } from "ahooks";
import { isEmpty } from "ramda";

const useAction = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [recycleData, setRecycleData] = useState<MetaShowerBatchDto>({
    count: 0,
    metaShowerBatches: [],
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchType, setSearchType] = useState<SearchType>(SearchType.All);

  const [keyword, setKeyword] = useState<string>("");

  const [sorter, setSorter] = useState({
    sortField: "createdDate",
    SortDirection: "desc",
  });

  const { message } = App.useApp();

  const navigate = useNavigate();

  const [pageParams, setPageParams] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 1,
    pageSize: 50,
  });

  const popupModal = ({
    keyword,
    config = { okText: "确认", cancelText: "取消" },
    callback,
  }: IPopupModaProps) => {
    const modal = Modal.confirm({
      okText: "确认",
      cancelText: "取消",
      onOk: () => callback(),
    });

    modal.update({
      title: `${keyword}提醒！`,
      content: `是否${keyword}${keyword === "清空" ? "全部" : "所选"}数据！`,
    });
  };

  const handleOperate = (type: ButtonOperationsEnum, callback: () => void) => {
    if (type === ButtonOperationsEnum.Clear) {
      popupModal({ keyword: "清除", callback });
    } else if (type === ButtonOperationsEnum.ClearAll) {
      popupModal({ keyword: "清空", callback });
    } else if (type === ButtonOperationsEnum.Restore) {
      popupModal({ keyword: "还原", callback });
    }
  };

  const loadData = useDebounceFn(
    (
      sortField: string = sorter.sortField,
      sortDirection: string = sorter.SortDirection
    ) => {
      setIsLoading((preValue) => !preValue);
      setSorter({ sortField: sortField, SortDirection: sortDirection });

      GetListData({
        PageIndex: pageParams.pageIndex,
        PageSize: pageParams.pageSize,
        DataType: DataType.Deleted,
        SortField: sortField,
        SortDirection:
          sortDirection === "ascend"
            ? "asc"
            : sortDirection === "descend"
            ? "desc"
            : "desc",
        KeyWord: keyword,
        SearchType: searchType,
      })
        .then((response) => {
          setTimeout(() => {
            setRecycleData(() => ({
              count: response?.count ?? 0,
              metaShowerBatches: response?.metaShowerBatches ?? [],
            }));

            setIsLoading((preValue) => !preValue);
            if (isEmpty(response.metaShowerBatches)) {
              setSorter({
                sortField: "createdDate",
                SortDirection: "desc",
              });
            }
          }, 500);
        })
        .catch((err) => {
          setTimeout(() => {
            message.error((err as Error).message);
            setIsLoading((preValue) => !preValue);
            setRecycleData({
              count: 0,
              metaShowerBatches: [],
            });
          }, 500);
        });
    },
    { wait: 500 }
  ).run;

  const reductionOrClear = (
    metaShowerBatchUuids: string[],
    operationType: OperationType
  ) => {
    DeleteOrRestoreMedia({
      metaShowerBatchUuids,
      operationType,
    })
      .then(() => {
        loadData();

        message.success(
          operationType === OperationType.Restore ? "还原成功" : "删除成功"
        );
      })
      .catch((err) => message.error((err as Error).message));
  };

  useEffect(() => loadData(), [pageParams.pageIndex, pageParams.pageSize]);

  return {
    selectedRowKeys,
    recycleData,
    isLoading,
    pageParams,
    message,
    setSelectedRowKeys,
    handleOperate,
    setPageParams,
    reductionOrClear,
    loadData,
    navigate,
    setSearchType,
    searchType,
    keyword,
    setKeyword,
  };
};

export default useAction;
