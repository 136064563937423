import { AppSettings } from "../../appsettings";

export const ArmsMonitor = (props: { children: JSX.Element }) => {
  const settings = (window as any).appsettings as AppSettings;
  const BrowserLogger = require("@arms/js-sdk");
  const __bl = BrowserLogger.singleton({
    pid: settings.armsPid,
    appType: "web",
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    enableSPA: true,
    useFmp: true,
    imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
  });

  return props.children;
};
