import { Modal, Form, Select, ConfigProvider } from "antd";
import { useAction, OutPutFormatValue } from "./hook";
import { productionModalProps } from "./props";
import {
  MetaShowerBatchType,
  MetaShowerOperationType,
} from "../../../../dtos/metashower";
import { SelectIcon } from "../svg-icon";
import { ResolutionRatio } from "../../../../dtos/digital-broadcasting";

export const ProductionModal = (props: productionModalProps) => {
  const {
    isShowModalOpen,
    metaShowerOperationType,
    isLoading,
    generateVideoType,
    proportion,
    setGenerateVideoType,
    setIsShowModalOpen,
    generateVideo,
    setVideoResolutions,
  } = props;

  const {
    outPutFormatOptions,
    videoOptions,
    run,
    resolutionRatio,
    handelSetResolutionRatio,
  } = useAction({
    proportion,
    generateVideo,
    setVideoResolutions,
  });

  const isVideoFormat = generateVideoType !== MetaShowerBatchType.Audio;

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            borderRadiusLG: 16,
          },
        }}
      >
        <Modal
          title={`${
            metaShowerOperationType === MetaShowerOperationType.Save
              ? "保存"
              : "生成"
          }数字人项目`}
          className="mt-24"
          open={isShowModalOpen}
          onOk={run}
          onCancel={() => setIsShowModalOpen(false)}
          okText="确定"
          cancelText="取消"
          confirmLoading={isLoading}
        >
          <Form className="my-5 flex">
            <Form.Item label="输出格式">
              <Select
                defaultValue={OutPutFormatValue[10]}
                style={{ width: 120 }}
                className="generateVideoSelect"
                popupClassName="text-center"
                onChange={(value) => setGenerateVideoType(Number(value))}
                options={outPutFormatOptions}
                suffixIcon={<SelectIcon />}
              />
            </Form.Item>
            <Form.Item label="分辨率" className="ml-8">
              {generateVideoType === MetaShowerBatchType.Audio ? (
                <div className="bg-[#F2F2F2] w-[3.875rem] rounded-lg h-[2rem] flex justify-center items-center text-[#8A8D95]">
                  無
                </div>
              ) : (
                <Select
                  className="productionSelect"
                  popupClassName="text-center"
                  key={isVideoFormat ? "1080 P" : "無"}
                  value={
                    isVideoFormat ? resolutionRatio : ResolutionRatio.Audio
                  }
                  style={{ width: 120 }}
                  options={videoOptions}
                  disabled={!isVideoFormat}
                  suffixIcon={<SelectIcon />}
                  onChange={(value: ResolutionRatio) =>
                    handelSetResolutionRatio(value)
                  }
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
