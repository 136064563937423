import { useEffect, useState } from "react";
import {
  GetAttachUrl,
  MediasDataType,
  MetaShowerGlobalMediasType,
  MetaShowerType,
} from "../../../../dtos/metashower";
import { GetAttachId, GetMediasUrl } from "../../../../api/metashower";
import { IMediaIdList } from "../../../../components/manage/props";
import App from "antd/es/app";

export const useAction = ({ mediaType }: { mediaType: MetaShowerType }) => {
  const { message } = App.useApp();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState<number>(1);

  const [mediasUrlList, setMediasUrlList] = useState<GetAttachUrl[]>([]);

  const [attachmentDto, setAttachmentDto] = useState<MediasDataType>({
    count: 0,
    metaShowerGlobalMedias: [],
  });

  const [isShowModalOpen, setIsShowModalOpen] = useState<boolean>(false);

  const getAttachment = () => {
    setIsLoading(true);

    GetAttachId({
      MediaType: mediaType,
      PageSize: mediaType === MetaShowerType.Avatar ? 11 : 6,
      PageIndex: pageIndex,
    })
      .then(async (res) => {
        if (res) {
          const data = res;

          setAttachmentDto({
            count: data.count,
            metaShowerGlobalMedias: data.metaShowerGlobalMedias ?? [],
          });

          const newMediaIdList: IMediaIdList[] = [];

          const attachmentIdList = res?.metaShowerGlobalMedias.map(
            (item: MetaShowerGlobalMediasType) => {
              newMediaIdList.push({
                attachmentId: item.attachmentId,
                mediaId: item.id,
              });
              return item.attachmentId;
            }
          );

          await GetMediasUrl({
            attachmentIds: attachmentIdList,
          })
            .then((res) => {
              if (res) {
                setTimeout(() => {
                  handlerNewArr(
                    data.metaShowerGlobalMedias,
                    res,
                    newMediaIdList
                  );
                  setIsLoading(false);
                }, 500);
              }
            })
            .catch((err) => {
              setTimeout(() => {
                message.error((err as Error).message);

                setAttachmentDto({
                  count: 0,
                  metaShowerGlobalMedias: [],
                });

                setMediasUrlList([]);
              }, 500);
            });
        }
      })
      .catch((err) => {
        setTimeout(() => {
          message.error((err as Error).message);

          setAttachmentDto({
            count: 0,
            metaShowerGlobalMedias: [],
          });

          setMediasUrlList([]);
        }, 500);
      });
  };

  const handlerNewArr = (
    ids: MetaShowerGlobalMediasType[],
    list: GetAttachUrl[],
    newMediaIdList: IMediaIdList[]
  ) => {
    const newArr = list.map((listItem) => {
      const getIndex = ids.filter((idsItem) => {
        if (listItem?.id === idsItem?.attachmentId) {
          return idsItem;
        }
      });

      const mediaId = newMediaIdList.filter((item) => {
        if (listItem?.id === item.attachmentId) {
          return item.mediaId;
        }
      });

      return {
        ...listItem,
        title: getIndex[0].title,
        sequentialNumber: getIndex[0].sequentialNumber,
        mediaId: mediaId[0].mediaId,
      };
    });

    setMediasUrlList(newArr);
  };

  useEffect(() => {
    getAttachment();
  }, [pageIndex]);

  return {
    isLoading,
    setPageIndex,
    attachmentDto,
    mediasUrlList,
    pageIndex,
    isShowModalOpen,
    setIsShowModalOpen,
    getAttachment,
  };
};
