import { Button, Input } from "antd";
import { AICopyCreationProps } from "./props";
import TextArea from "antd/es/input/TextArea";
import { AIDialog } from "../ai-dialog";

import { useAction } from "./hook";
import { useMsgContext } from "../contexts/msgContext";

export const AICopyCreation = (props: AICopyCreationProps) => {
  const { navigate, inputTitle, inputContext, setInputTitle, setInputContext } =
    useAction();

  const { changeNewProductionStatus } = props;

  const { setTitle, setContext } = useMsgContext();

  return (
    <div className="w-full h-full flex-1 flex flex-col box-border overflow-y-auto no-scrollbar">
      <div className="px-[2.5rem] flex-1 flex flex-col">
        <div className="text-xl font-medium leading-[1.875rem] mt-[1rem]">
          新建製作
        </div>
        <div className="h-[2.625rem] mt-[.5rem] pr-[3.5rem] min-w-[25rem]">
          <div className="h-full w-1/2">
            <Input
              allowClear
              showCount={{
                formatter: (info: {
                  value: string;
                  count: number;
                  maxLength?: number;
                }) => {
                  return (
                    <span className="text-[#0B1121] text-[.875rem] mr-[.75rem]">
                      {info.count}/{info.maxLength}
                    </span>
                  );
                },
              }}
              maxLength={40}
              placeholder="請輸入標題"
              bordered={false}
              style={{
                backgroundColor: "white",
                padding: 0,
                width: "100%",
                height: "100%",
                fontSize: "1rem",
              }}
              className="myCreateInput"
              onChange={(e) => setInputTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-3 mb-4 text-base">編號：——————</div>
        <div className="flex-1 flex space-x-4">
          <div className="w-1/2 flex flex-col space-y-3">
            <span className="text-base">文字輸入：</span>
            <div className="h-full bg-white rounded-lg pb-[2.125rem]">
              <TextArea
                style={{
                  resize: "none",
                  backgroundColor: "white",
                }}
                className="flex-1 h-full myCreateTextArea"
                allowClear
                maxLength={4000}
                showCount={{
                  formatter: (info: {
                    value: string;
                    count: number;
                    maxLength?: number;
                  }) => {
                    return (
                      <span className="text-[#0B1121] text-[.75rem] absolute right-3 -bottom-1">
                        字数：{info.count}/{info.maxLength}
                      </span>
                    );
                  },
                }}
                bordered={false}
                placeholder="輸入你想寫的... ..."
                onChange={(e) => setInputContext(e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2 flex flex-col space-y-3">
            <span className="text-base">AI 文案：</span>
            <AIDialog originalText={inputContext} />
          </div>
        </div>
      </div>

      <div className="flex justify-end bg-white mt-[1.625rem] py-[1.5rem] pr-[1rem] rounded-b-md">
        <Button
          className="mr-[.5rem] w-[4.75rem] h-[2.5rem] rounded-lg"
          onClick={() => changeNewProductionStatus(false)}
        >
          取消
        </Button>
        <Button
          type="primary"
          className="w-[5.625rem] h-[2.5rem] rounded-lg"
          onClick={() => {
            setTitle(inputTitle);
            setContext(inputContext);
            navigate("/digitalBroadcasting/new");
            changeNewProductionStatus(false);
          }}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};
