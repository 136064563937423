import { useEffect, useState } from "react";

import { MetaShowerType } from "../../../../dtos/metashower";
import { ImageType } from "../../../../dtos/digital-broadcasting";

export const useAction = (props: {
  handleGetImageUrl: (type: number, mediaId: number | null) => void;
}) => {
  const { handleGetImageUrl } = props;

  const [bgAndAvatar, setBgAndAvatar] = useState<{
    avatarCard: null | number;
    bgCard: null | number;
  }>({
    avatarCard: 0,
    bgCard: 0,
  });

  const updateBgAndAvatar = (type: MetaShowerType, value: null | number) => {
    switch (type) {
      case MetaShowerType.Avatar:
        setBgAndAvatar((prev) => ({
          ...prev,
          avatarCard: value,
        }));
        break;
      case MetaShowerType.Background:
        setBgAndAvatar((prev) => ({
          ...prev,
          bgCard: value,
        }));
        break;
    }
  };

  //回调url给外层
  useEffect(() => {
    handleGetImageUrl(ImageType.ScreenBackground, bgAndAvatar.bgCard);
  }, [bgAndAvatar.bgCard]);

  useEffect(() => {
    handleGetImageUrl(ImageType.DigitalImage, bgAndAvatar.avatarCard);
  }, [bgAndAvatar.avatarCard]);

  return {
    updateBgAndAvatar,
    bgAndAvatar,
  };
};
