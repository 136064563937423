import { useAction } from "./hook";
import { ModalVideo } from "../../../../components/modal";
import { AICopyCreation } from "../ai-copy-creation";
import { Empty, Input, Modal, Pagination, Spin } from "antd";
import { VideoComponent } from "./components/video";
import { MediaPage } from "../../../../dtos/metashower";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { noDataIcon } from "../../../../components/no-data";

const Home = () => {
  const {
    list,
    loading,
    totalMsg,
    clickVideo,
    videoUrl,
    isShowVideo,
    onCancel,
    getPageNum,
    videoText,
    clickDetail,
    handleDebounceClick,
    newProductionStatus,
    changeNewProductionStatus,
    changeLoadFlag,
    setSearchValue,
    navigate,
    pageIndex,
    setLoading,
    searchValue,
  } = useAction();

  const isEmptyList = !list || list.length === 0;

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col flex-1 p-[1rem_0] overflow-y-auto">
        <div className="flex justify-between items-center mb-[1rem] mr-[1rem] max-sm:flex-wrap">
          <div className="flex items-center justify-center">
            <div
              className="w-[6.5rem] leading-[2.5rem] text-center bg-[#4D7CEE] text-[#fff] rounded-[0.5rem] cursor-pointer font-medium"
              onClick={() => changeNewProductionStatus(true)}
            >
              新建製作
            </div>
            <div
              onClick={() => {
                navigate("/home/recycling");
              }}
              className="w-[5.625rem] leading-[2.5rem] text-center mx-[1rem] rounded-[0.5rem] bg-[#FF935E] text-[#fff] cursor-pointer font-medium"
            >
              回收站
            </div>
            <div
              className="w-[2.5rem] h-[2.5rem] bg-[url('/src/assets/images/home/reload.png')] bg-no-repeat bg-cover cursor-pointer"
              onClick={() => {
                if (loading) return;
                setLoading(true);
                handleDebounceClick();
              }}
            />
          </div>
          <div className="w-[25rem] max-sm:mt-4 border-[#E9E9E9] border-[.0625rem] border-solid rounded-[0.5rem] bg-[#fff]">
            <Input
              value={searchValue}
              prefix={<SearchOutlined className="text-[#C2C5C8]" />}
              suffix={
                <CloseOutlined
                  className="text-[#C2C5C8] text-[.75rem]"
                  onClick={() => setSearchValue("")}
                />
              }
              className="h-[2.5rem] border-[0]"
              placeholder={"Please input ..."}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        {!loading ? (
          <>
            {isEmptyList ? (
              <Empty
                description=""
                className="flex h-screen justify-center pt-[8%]"
                image={noDataIcon()}
                imageStyle={{ height: "max-content" }}
              />
            ) : (
              <div className="flex-1 overflow-y-auto no-scrollbar">
                <div
                  className={`grid grid-cols-[repeat(auto-fill,minmax(20.125rem,1fr))] box-border`}
                >
                  {list?.map((item, key) => (
                    <VideoComponent
                      key={key}
                      item={item}
                      status={item.status}
                      clickVideo={clickVideo}
                      changeLoadFlag={changeLoadFlag}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <Spin size="large" spinning={loading} />
          </div>
        )}
        <div className="flex justify-end items-center pt-[1rem] homePagination px-[1.5rem]">
          <Pagination
            current={pageIndex}
            pageSize={MediaPage.PageSize}
            onChange={getPageNum}
            showSizeChanger={false}
            total={totalMsg}
          />
        </div>
      </div>

      <Modal
        destroyOnClose={true}
        footer={null}
        open={newProductionStatus}
        width={1500}
        className="myCreateModal"
        centered={true}
        maskClosable={false}
        onCancel={() => {
          changeNewProductionStatus(false);
        }}
      >
        <AICopyCreation changeNewProductionStatus={changeNewProductionStatus} />
      </Modal>

      <ModalVideo
        videoUrl={videoUrl}
        onCancel={onCancel}
        type={"video"}
        status={isShowVideo}
      />

      <ModalVideo
        videoText={videoText}
        onCancel={onCancel}
        type={"text"}
        status={clickDetail}
      />
    </div>
  );
};

export default Home;
