import { IVideoComponentProps, DropdownItemEnum } from "./props";
import {
  MetaShowerBatchStatus,
  MetaShowerBatchType,
} from "../../../../../../dtos/metashower";
import moment from "moment";
import {
  Tooltip,
  Card,
  Dropdown,
  MenuProps,
  ConfigProvider,
  Modal,
} from "antd";
import { useAction } from "./hook";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import downIcon from "../../../../../../assets/images/home/down.png";

export const VideoComponent = (props: IVideoComponentProps) => {
  const { status, item, clickVideo, changeLoadFlag } = props;

  const {
    popupModal,
    downloadVideo,
    showDel,
    playAudio,
    handleCancel,
    handleOk,
    isDelLoading,
  } = useAction({
    changeLoadFlag,
  });

  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: DropdownItemEnum.Delete,
      onClick: () => popupModal(item.uuid),
      label: <div>删除</div>,
    },
  ];

  if (status === MetaShowerBatchStatus.Completed) {
    items.unshift({
      key: DropdownItemEnum.Preview,
      onClick: () =>
        item.type !== MetaShowerBatchType.Audio
          ? clickVideo(item)
          : playAudio(item.mediaUrl),
      label: <div>预览</div>,
    });
  }

  const showTypeText = (status: MetaShowerBatchStatus) => {
    switch (status) {
      case MetaShowerBatchStatus.InProgress:
        return "製作中";

      case MetaShowerBatchStatus.Draft:
        return "草稿";

      case MetaShowerBatchStatus.Queuing:
        return "排隊中";

      case MetaShowerBatchStatus.Pending:
        return "等待中";

      case MetaShowerBatchStatus.Failed:
        return "製作失敗";

      default:
        return "";
    }
  };

  const statusText = showTypeText(status);

  return (
    <div className="flex mb-[2.5rem]">
      <Card
        hoverable
        className="w-full overflow-hidden bg-[#fff] mr-[1rem] border-none relative"
        bodyStyle={{ padding: "1.25rem 1.125rem .75rem" }}
        cover={
          status === MetaShowerBatchStatus.Completed ? (
            item.type !== MetaShowerBatchType.Audio ? (
              <div onClick={() => clickVideo(item)}>
                <ReactPlayer
                  width={"100%"}
                  height={"17.75rem"}
                  light={false}
                  playing={false}
                  url={item.mediaUrl}
                />
              </div>
            ) : (
              <div className="w-full h-[17.5rem] text-center leading-[17.5rem] bg-[#f4f4f4]">
                音頻
              </div>
            )
          ) : (
            <div
              className={`h-[17.75rem] bg-[#f4f4f4] rounded-[0.2rem] text-center leading-[17.75rem] bg-[url('/src/assets/images/home/poster.png')] bg-no-repeat bg-cover `}
            />
          )
        }
      >
        <div
          className={`absolute top-[2.5%] left-[3%] min-w-[3.9375rem] max-w-max leading-[2rem] text-center opacity-[80%] rounded-[.375rem] font-medium ${
            status === MetaShowerBatchStatus.InProgress
              ? "bg-[#4D7CEE] text-[#fff]"
              : status === MetaShowerBatchStatus.Draft
              ? "bg-[#FF935E] text-[#fff]"
              : "bg-[#F2F2F2] text-[#222]"
          }`}
        >
          {statusText}
        </div>
        <div>
          <div className="flex justify-between items-center mb-[.75rem]">
            <Tooltip title={item.title} className="flex-[7]">
              <span className="text-[.875rem] font-medium truncate">
                {statusText && "[" + statusText + "] "}
                {item.title}
              </span>
            </Tooltip>
            <Dropdown
              overlayClassName="homeDropdown"
              overlayStyle={{
                textAlign: "center",
              }}
              menu={{ items }}
              trigger={["click"]}
              placement="bottomLeft"
            >
              <div className="flex items-center justify-between hover:bg-[#4D7CEE] bg-[#4D7CEE] text-[#fff] h-[2rem] pl-[.625rem] pr-[.5rem] box-border rounded-[.375rem]">
                <div>更多</div>
                <img src={downIcon} alt="" />
              </div>
            </Dropdown>
          </div>
          <div className="text-[1rem] text-[#0B1121] truncate pb-[.1875rem]">
            <Tooltip title={item.sequentialNumber}>
              編號 : {item.sequentialNumber ?? "——————"}
            </Tooltip>
          </div>
          <div className="w-4/5">
            <span className="text-[.875rem] text-[#0B1121] truncate">{`時間 : ${
              status === MetaShowerBatchStatus.Completed
                ? moment
                    .utc(item.createdDate)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")
                : "——————"
            }`}</span>
          </div>
          <div
            className={`mt-[1.25rem] leading-[2.5rem] text-center rounded-[0.5rem] ${
              status === MetaShowerBatchStatus.Completed
                ? "bg-[#4D7CEE] text-[#fff]"
                : status === MetaShowerBatchStatus.Draft
                ? "bg-[#FF935E] text-[#fff]"
                : "bg-[#F2F2F2] text-[#C2C5C8]"
            }`}
            onClick={() => {
              status === MetaShowerBatchStatus.Draft &&
                navigate(`/digitalBroadcasting/${item.id}`);
              status === MetaShowerBatchStatus.Completed &&
                downloadVideo(
                  item.mediaUrl,
                  item.type !== MetaShowerBatchType.Audio
                    ? item.title +
                        item.mediaUrl.substring(item.mediaUrl.lastIndexOf("."))
                    : item.title
                );
            }}
          >
            {status === MetaShowerBatchStatus.Completed ||
            status === MetaShowerBatchStatus.InProgress
              ? "下載"
              : status === MetaShowerBatchStatus.Draft
              ? "繼續製作"
              : status === MetaShowerBatchStatus.Failed
              ? "製作失敗"
              : "等待製作"}
          </div>
        </div>
      </Card>
      <ConfigProvider theme={{ token: { colorPrimary: "#4D7CEE" } }}>
        <Modal
          title="删除提醒！"
          open={showDel}
          onOk={handleOk}
          confirmLoading={isDelLoading}
          onCancel={handleCancel}
          okText="确认"
          cancelText="取消"
        >
          <div className="mt-4">是否删除该视频（草稿）？</div>
        </Modal>
      </ConfigProvider>
    </div>
  );
};
