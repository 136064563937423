import { Button, Input, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Paragraph from "antd/es/typography/Paragraph";
import { useAction } from "./hooks";
import { GenderTypeEnum, LanguageTypeEnum } from "../../dtos/voice-clone";

const VoiceClone = () => {
  const {
    isReasoning,
    isTraning,
    handleTraining,
    handleReasoning,
    reasonUrl,
    voiceCloneReasonData,
    voiceCloneTrainData,
    updateVoiceCloneData,
    setVoiceCloneTrainData,
    setVoiceCloneReasonData,
  } = useAction();

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col ml-10 mt-10">
        <div className="text-[2rem] font-bold m-5">训练</div>
        <div className="flex flex-col w-[40rem] ml-6">
          <Input
            placeholder="输入数据集地址"
            value={voiceCloneTrainData.dataset}
            onChange={(e) =>
              updateVoiceCloneData(
                "dataset",
                e.target.value,
                setVoiceCloneTrainData
              )
            }
          />
          <div className="flex justify-between mt-5">
            <div className="flex items-center text-[1rem]">
              <span>语言：</span>
              <Select
                defaultValue={LanguageTypeEnum.EnCn}
                value={voiceCloneTrainData.type}
                style={{ width: 150 }}
                onChange={(e) =>
                  updateVoiceCloneData("type", e, setVoiceCloneTrainData)
                }
                options={[
                  { value: LanguageTypeEnum.EnCn, label: "中英文" },
                  { value: LanguageTypeEnum.Canton, label: "粤语" },
                ]}
              />
            </div>
            <div className="flex items-center text-[1rem]">
              <span>名字：</span>
              <Input
                placeholder="请输入名字"
                className="w-[10rem]"
                value={voiceCloneTrainData.name}
                onChange={(e) =>
                  updateVoiceCloneData(
                    "name",
                    e.target.value,
                    setVoiceCloneTrainData
                  )
                }
              />
            </div>
            <div className="flex items-center text-[1rem]">
              <span>性别：</span>
              <Radio.Group
                name="sex"
                defaultValue={GenderTypeEnum.Male}
                value={voiceCloneTrainData.genderType}
                onChange={(e) =>
                  updateVoiceCloneData(
                    "genderType",
                    e.target.value,
                    setVoiceCloneTrainData
                  )
                }
              >
                <Radio value={GenderTypeEnum.Male} className="text-[1rem]">
                  男
                </Radio>
                <Radio value={GenderTypeEnum.Famale} className="text-[1rem]">
                  女
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={handleTraining}
              type="primary"
              className="h-[2rem] w-[7rem] font-medium text-center"
              loading={isTraning}
            >
              开始训练
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col ml-10">
        <div className="text-[2rem] font-bold ml-5 mb-5">推理</div>
        <div className="flex flex-col w-[40rem] ml-6">
          <TextArea
            showCount
            placeholder="请输入推理文本"
            maxLength={300}
            autoSize={{ minRows: 2, maxRows: 8 }}
            value={voiceCloneReasonData.text}
            onChange={(e) =>
              updateVoiceCloneData(
                "text",
                e.target.value,
                setVoiceCloneReasonData
              )
            }
          />
          <div className="flex justify-between mt-5">
            <div className="flex items-center text-[1rem]">
              <span>语言：</span>
              <Select
                defaultValue={LanguageTypeEnum.EnCn}
                value={voiceCloneReasonData.type}
                style={{ width: 150 }}
                onChange={(e) =>
                  updateVoiceCloneData("type", e, setVoiceCloneReasonData)
                }
                options={[
                  { value: LanguageTypeEnum.EnCn, label: "中英文" },
                  { value: LanguageTypeEnum.Canton, label: "粤语" },
                ]}
              />
            </div>
            <div className="flex items-center text-[1rem]">
              <span>名字：</span>
              <Input
                placeholder="请输入名字"
                className="w-[10rem]"
                value={voiceCloneReasonData.name}
                onChange={(e) =>
                  updateVoiceCloneData(
                    "name",
                    e.target.value,
                    setVoiceCloneReasonData
                  )
                }
              />
            </div>
            <div className="flex items-center text-[1rem]">
              <span>性别：</span>
              <Radio.Group
                name="sex"
                defaultValue={GenderTypeEnum.Male}
                value={voiceCloneReasonData.genderType}
                onChange={(e) =>
                  updateVoiceCloneData(
                    "genderType",
                    e.target.value,
                    setVoiceCloneReasonData
                  )
                }
              >
                <Radio value={GenderTypeEnum.Male} className="text-[1rem]">
                  男
                </Radio>
                <Radio value={GenderTypeEnum.Famale} className="text-[1rem]">
                  女
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="flex justify-between mt-4 items-center">
            <div className="w-[20rem]">
              {reasonUrl && (
                <Paragraph
                  copyable={{ tooltips: false }}
                  className="text-[1rem] mt-1.5 break-all"
                >
                  {reasonUrl}
                </Paragraph>
              )}
            </div>
            <Button
              onClick={handleReasoning}
              type="primary"
              className="h-[2rem] w-[7rem] font-medium text-center"
              loading={isReasoning}
            >
              开始推理
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceClone;
