import { useEffect, useState } from "react";
import { SwitchProperty } from "../../dtos/switch";

const Switch = (props: SwitchProperty) => {
  const { checked, onChange } = props;
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onInputChange = (e: any) => {
    const value = e.target.checked;
    setIsChecked(value);
    if (!onChange) {
      return;
    }
    onChange(value);
  };

  return (
    <label className="relative">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onInputChange}
        className="absolute top-0 left-0 w-full h-full z-[2] border-0 appearance-none cursor-pointer"
      />
      <div
        className={`relative w-[47px] h-[24px] rounded-[28px] z-0 m-0 p-0 appearance-none border-0 transition-all duration-200 after:duration-200 after:transition-all after:absolute after:left-[4px] after:top-[3px] after:w-[18px] after:h-[18px] after:rounded-full after:bg-white after:content-[''] ${
          isChecked ? "bg-[#4D7CEE] after:translate-x-[21px]" : "bg-[#CBCBCB]"
        }`}
      ></div>
    </label>
  );
};

export default Switch;
