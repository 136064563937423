import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import routes from "../../routes";

export const useAction = () => {
  const { signout, username } = useAuth();
  const location = useLocation();
  const { setNewProductionStatus } = useAuth();

  const [openKey, setOpenKey] = useState<string[]>([]);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    location.pathname,
  ]);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpenKey([location.pathname.split("/").slice(0, 2).join("/")]);
    const childrenItem = routes.find(
      (x) => x.path === location.pathname
    )?.children;
    setSelectedKeys(
      !!childrenItem ? [childrenItem[0].path] : [location.pathname]
    );
  }, [location.pathname]);

  const weatherToHideTheMenu = useMemo(() => {
    const array = ["/digitalBroadcasting"];

    return array.some((item) => location.pathname.includes(item));
  }, [location.pathname]);

  return {
    username,
    openKey,
    selectedKeys,
    collapsed,
    signout,
    setCollapsed,
    setOpenKey,
    navigate,
    weatherToHideTheMenu,
    setNewProductionStatus,
  };
};
