export enum MetaShowerBatchStatus {
  Draft = 9,
  Pending = 10,
  Queuing = 11,
  InProgress = 20,
  Completed = 80,
  Failed = 99,
}

export enum MetaShowerBatchType {
  OriginalVideo = 10, // 原始视频生成
  OriginalVideoWithOtherMedia = 20, // 原始视频 + 其他媒体（同时）
  InsertMediaAfterOriginalVideoGenerated = 30, // 原始生成 + 其他媒体（后期）//先不需要这种
  Audio = 40, // 音频
}

export interface MetaShowerBatchDto {
  count: number;
  metaShowerBatches: MetaShowerBatch[];
}

export interface MetaShowerBatch {
  backgroundAttachmentType: BackgroundAttachmentType;
  backgroundMediaPosition: BackgroundMediaPositionDto;
  backGroundMediaPositionJson: string;
  combinedImageAttachmentId: number;
  doneDate: string;
  fileDuration: number;
  fileDurationString: string;
  fileSize: number;
  id: number;
  insertMediaUrls: string[];
  insertMediaUrlsJson: string;
  insertTimes: number[][];
  insertTimesJson: string;
  isDelete: boolean;
  mediaUrl: string;
  processDate: string;
  proportionSettingJson: string[];
  queueDate: string[];
  resolutionsSettingJson: string;
  status: MetaShowerBatchStatus;
  text: string;
  title: string;
  createdDate: string;
  proportion: number[];
  pitchRate: number;
  language: number;
  speechRate: number;
  type: number;
  volume: number;
  resolution: number[];
  uuid: string;
  sequentialNumber: string;
  mediaPosition: MediaPositionsType[];
  metaShowerGlobalMedias?: GlobalMediasType[];
}

export interface GlobalMediasType {
  id: number;
  uuid: string;
  userId: number;
  title: string;
  isPublic: boolean;
  attachmentId: number;
  createdDate: string;
  sequentialNumber: string;
  mediaType: MetaShowerType;
}

export enum MetaShowerOperationType {
  Save = 0, //保存
  Generate = 1, //生成
}

export enum MetaShowerMediaType {
  Avatar,
  Background,
}

export interface MediaPositionsType {
  positionFrom: MetaShowerMediaType;
  coordinateX: number;
  coordinateY: number;
  width: number;
  height: number;
}

export interface BackgroundMediaPositionDto {
  backGroundLength: number;
  backGroundWidth: number;
  coordinateX: number;
  coordinateY: number;
}

export enum BackgroundAttachmentType {
  Image,
  Video,
}

export interface AddMetaShowerCommand {
  uuid: string;
  text: string;
  title: string;
  language: number | null;
  mediaIds: number[] | [];
  combinedImageAttachmentId: number | undefined;
  volume: number;
  speechRate: number | undefined;
  pitchRate: number;
  videoProportion: number[];
  videoResolutions: number[];
  mediaInsertTimes: number[][];
  mediaInsertMediaUrls: string[];
  metaShowerOperationType: MetaShowerOperationType;
  isPreviewAudioBatch: boolean;
  mediaPositions: MediaPositionsType[];
  type: MetaShowerBatchType;
  backgroundAttachmentType: BackgroundAttachmentType | null;
}

export enum MetaShowerType {
  Avatar = 0,
  Background = 1,
  Pronounce = 2,
}

export interface MediasDataType {
  count: number;
  metaShowerGlobalMedias: MetaShowerGlobalMediasType[];
}

export interface MetaShowerGlobalMediasType {
  id: number;
  uuid: string;
  userId: number;
  attachmentId: number;
  createdDate: string;
  mediaType: number;
  title: string;
  isPublic: boolean;
  sequentialNumber: string;
}

export interface MediaType {
  mediatype: number;
}

export interface GetAttachUrl {
  id: number;
  uuid: string;
  createdDate: string;
  fileUrl: string;
  fileName: string;
  fileSize: number;
  filePath: string;
  title?: string;
  fileContent: string | null;
  sequentialNumber?: string;
  mediaId?: number;
}

export interface GetMediaType {
  MediaType?: number;
  PageIndex: number;
  PageSize: number;
  KeyWord?: string;
  DataType?: DataType;
  SortField?: string;
  SortDirection?: string;
  SearchType?: SearchType;
}

export enum SearchType {
  All,
  Title,
  Number,
}

export enum DataType {
  Nomal, // 首页
  Deleted, // 回收站
  All, // 全部 默认
}

export enum MediaPage {
  PageIndex = 1,
  PageSize = 30,
}

export interface AttachmentId {
  attachmentIds: number[];
}

export interface UploadParams {
  ContentType: string;
  ContentDisposition: string;
  Headers: object;
  length: number;
  Name: string;
  FileName: string;
}

export interface PostMediaParams {
  attachmentIds?: AttachmentId;
  metaShowerGlobalMediaData?: {
    title?: string;
    attachmentIds: number[];
    mediaType: MetaShowerType;
    isPublic?: boolean;
  }[];
}

export enum ManageClickType {
  Open = "open",
  Close = "close",
  Upload = "upload",
}

export enum UploadTitle {
  BackgroundTitle = "上傳背景",
  Avatar = "上傳頭像",
}

export enum ShowToastTips {
  success = "提交成功!",
  UploadImg = "请先上传图片",
  loading = "图片加载中...",
}

export enum LanguageVoiceType {
  MandarinMale = 1,
  MandarinFemale = 2,
  AmericanEnglishMale = 3,
  AmericanEnglishFemale = 4,
  CantoneseFemale = 6,
  JapaneseMale = 7,
  JapaneseFemale = 8,
}

export enum ImageIdType {
  MediaId,
  AttachmentId,
}

export interface GetMediaFileContentData {
  Id: number;
  Type: ImageIdType;
}
