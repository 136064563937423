export const myProjectIcon = (isClick: boolean) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Component 6" clipPath="url(#clip0_136_3492)">
        <path
          id="Vector"
          d="M17.5002 2.5H2.50016C1.5835 2.5 0.833496 3.25 0.833496 4.16667V14.1667C0.833496 15.0833 1.5835 15.8333 2.50016 15.8333H6.66683V17.5H13.3335V15.8333H17.5002C18.4168 15.8333 19.1668 15.0833 19.1668 14.1667V4.16667C19.1668 3.25 18.4168 2.5 17.5002 2.5ZM17.5002 14.1667H2.50016V4.16667H17.5002V14.1667ZM15.8335 6.66667H6.66683V8.33333H15.8335V6.66667ZM15.8335 10H6.66683V11.6667H15.8335V10ZM5.8335 6.66667H4.16683V8.33333H5.8335V6.66667ZM5.8335 10H4.16683V11.6667H5.8335V10Z"
          fill={isClick ? "#4D7CEE" : "#323232"}
        />
      </g>
      <defs>
        <clipPath id="clip0_136_3492">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const digitalHumanBroadcastIcon = (isClick: boolean) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Component 6" clipPath="url(#clip0_136_3507)">
        <path
          id="Vector"
          d="M16.6665 3.33333H3.33317C2.4165 3.33333 1.6665 4.08333 1.6665 5V15C1.6665 15.9167 2.4165 16.6667 3.33317 16.6667H16.6665C17.5832 16.6667 18.3332 15.9167 18.3332 15V5C18.3332 4.08333 17.5832 3.33333 16.6665 3.33333ZM16.6665 15H3.33317V5H16.6665V15ZM3.33317 0H16.6665V1.66667H3.33317V0ZM3.33317 18.3333H16.6665V20H3.33317V18.3333ZM9.99984 10C11.1498 10 12.0832 9.06667 12.0832 7.91667C12.0832 6.76667 11.1498 5.83333 9.99984 5.83333C8.84984 5.83333 7.9165 6.76667 7.9165 7.91667C7.9165 9.06667 8.84984 10 9.99984 10ZM9.99984 7.08333C10.4582 7.08333 10.8332 7.45833 10.8332 7.91667C10.8332 8.375 10.4582 8.75 9.99984 8.75C9.5415 8.75 9.1665 8.375 9.1665 7.91667C9.1665 7.45833 9.5415 7.08333 9.99984 7.08333ZM14.1665 13.325C14.1665 11.5833 11.4082 10.8333 9.99984 10.8333C8.5915 10.8333 5.83317 11.5833 5.83317 13.325V14.1667H14.1665V13.325ZM7.3415 12.9167C7.84984 12.4833 9.03317 12.0833 9.99984 12.0833C10.9748 12.0833 12.1582 12.4833 12.6665 12.9167H7.3415Z"
          fill={isClick ? "#4D7CEE" : "#323232"}
        />
      </g>
      <defs>
        <clipPath id="clip0_136_3507">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const digitalAvatarIcon = (isClick: boolean) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Component 6" clipPath="url(#clip0_136_3516)">
        <path
          id="Vector"
          d="M5.83333 14.9998H7.5V4.99984H5.83333V14.9998ZM9.16667 18.3332H10.8333V1.6665H9.16667V18.3332ZM2.5 11.6665H4.16667V8.33317H2.5V11.6665ZM12.5 14.9998H14.1667V4.99984H12.5V14.9998ZM15.8333 8.33317V11.6665H17.5V8.33317H15.8333Z"
          fill={isClick ? "#4D7CEE" : "#323232"}
        />
      </g>
      <defs>
        <clipPath id="clip0_136_3516">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const backgroundManagementIcon = (isClick: boolean) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Component 6" clipPath="url(#clip0_136_3525)">
        <g id="Group">
          <path
            id="Vector"
            d="M16.6668 12.9998H3.3335V11.3332H16.6668V12.9998ZM16.6668 14.6665H3.3335V16.3332H16.6668V14.6665ZM12.5002 9.6665L16.6668 6.70817V4.6665L12.5002 7.62484L8.3335 4.6665L3.3335 7.7165V9.6665L8.26683 6.65817L12.5002 9.6665Z"
            fill={isClick ? "#4D7CEE" : "#323232"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_136_3525">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
