import { Manage } from "../../components/manage";
import { MetaShowerType, UploadTitle } from "../../dtos/metashower";

const Digitizing = () => {
  return (
    <Manage addTitle={UploadTitle.Avatar} pageType={MetaShowerType.Avatar} />
  );
};

export default Digitizing;
