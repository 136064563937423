export interface DraggableElementType {
  xCoordinate: number;
  yCoordinate: number;
  width: number;
  height: number;
  imageUrl?: string;
  text?: string;
  fontSize?: number;
  mediaId: number | null;
}

export interface SettingDataType {
  title: string;
  changeType: ChangeType;
  key: string;
  value: boolean | string | number;
  options?: { label: string; value: string | number }[];
}

export enum Proportions {
  SixteenToNine = "16:9",
  FourToThree = "4:3",
  NineToSixteen = "9:16",
  ThreeToFour = "3:4",
}

export const ProportionsValue = {
  [Proportions.SixteenToNine as string]: "16 / 9",
  [Proportions.FourToThree as string]: "4 / 3",
  [Proportions.NineToSixteen as string]: "9 / 16",
  [Proportions.ThreeToFour as string]: "3 / 4",
};

export enum ResolutionRatio {
  SD,
  HD,
  FullHD,
  Audio,
}

export const ResolutionRatioSD = {
  [Proportions.SixteenToNine]: [854, 480],
  [Proportions.FourToThree]: [640, 480],
  [Proportions.NineToSixteen]: [480, 854],
  [Proportions.ThreeToFour]: [480, 640],
};

export const ResolutionRatioHD = {
  [Proportions.SixteenToNine]: [1280, 720],
  [Proportions.FourToThree]: [1024, 768],
  [Proportions.NineToSixteen]: [720, 1280],
  [Proportions.ThreeToFour]: [768, 1024],
};

export const ResolutionRatioFullHD = {
  [Proportions.SixteenToNine]: [1920, 1080],
  [Proportions.FourToThree]: [1440, 1080],
  [Proportions.NineToSixteen]: [1080, 1920],
  [Proportions.ThreeToFour]: [1080, 1440],
};

export enum Speed {
  ZeroPointTwoFive = 0.25,
  ZeroPointFive = 0.5,
  ZeroPointSevenFive = 0.75,
  One = 1.0,
  OnePointTwoFive = 1.25,
  OnePointFive = 1.5,
  Two = 2.0,
  Three = 3.0,
}

export enum ChangeType {
  Switch,
  Select,
}

export enum ImageType {
  ScreenBackground,
  DigitalImage,
  Captions,
}

export interface PronunciationType {
  volume: number;
  speechRate: number;
  language: number;
}
