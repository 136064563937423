export const videoFailLoad = () => {
  return (
    <svg
      width="230"
      height="158"
      viewBox="0 0 230 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="&#229;&#138;&#160;&#232;&#188;&#137;&#229;&#164;&#177;&#230;&#149;&#151;"
        clipPath="url(#clip0_103_5724)"
      >
        <path
          id="Vector"
          d="M109.038 145.4C108.798 145.344 108.578 145.221 108.404 145.046C109.106 145.329 109.402 145.477 109.038 145.4Z"
          fill="#2F2E41"
        />
        <g id="Group 1597882887">
          <path
            id="Vector_2"
            d="M94.6092 117.77C92.8292 119.25 89.6914 126.528 89.6914 126.528C90.6955 126.634 91.706 126.666 92.7148 126.624L92.3617 124.691L93.6741 126.547C94.1862 126.492 94.692 126.389 95.1851 126.24L94.6416 123.266L96.3385 125.665C96.3511 125.655 96.364 125.645 96.3763 125.635C98.1563 124.154 99.2037 121.193 98.7157 119.021C98.2277 116.849 96.3892 116.289 94.6092 117.77Z"
            fill="#4D7CEE"
          />
          <path
            id="Vector_3"
            d="M144.318 73.7512C143.671 74.7468 143.261 77.2558 143.015 79.4351L144.234 80.7366L142.927 80.273C142.766 81.9043 142.703 83.1775 142.703 83.1775C142.703 83.1775 147.949 82.0389 149.192 80.1245C150.435 78.21 150.352 75.2313 149.006 73.4714C147.66 71.7115 145.561 71.8368 144.318 73.7512Z"
            fill="#4D7CEE"
          />
          <path
            id="Vector_4"
            d="M149.776 110.491C149.184 111.487 148.807 113.996 148.582 116.176L149.697 117.477L148.501 117.014C148.353 118.645 148.295 119.918 148.295 119.918C148.295 119.918 153.098 118.778 154.236 116.863C155.375 114.948 155.3 111.97 154.068 110.21C152.836 108.451 150.914 108.577 149.776 110.491Z"
            fill="#FF935E"
          />
          <path
            id="Vector_5"
            d="M167.814 147.127C152.926 150.425 133.443 149.801 112.537 147.151C95.4366 144.983 76.152 139.562 60.5 133.249C62.116 131.612 64.2311 130.561 66.5102 130.264C66.5741 130.255 66.64 130.245 66.7056 130.238C67.8596 130.1 69.026 130.1 70.1799 130.239C84.7968 131.883 82.1142 139.9 101.832 130.908C114.095 125.317 127.286 124.53 135.995 126.429C136.156 126.464 136.314 126.5 136.472 126.536C141.51 127.714 144.955 129.812 145.7 132.395C147.734 139.443 138.633 143.722 154.254 144.03C158.588 144.116 163.444 146.055 167.814 147.127Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_6"
            d="M227.868 155.687C221.891 155.203 215.927 154.284 209.982 153.51C201.602 152.42 193.226 151.296 184.855 150.139C168.26 147.863 151.674 145.514 135.084 143.2C118.378 140.87 101.668 138.574 84.9523 136.312C69.4581 134.213 53.9577 132.161 38.4512 130.155C26.8116 128.666 15.1645 127.228 3.48779 126.062C2.59104 125.973 1.6942 125.884 0.797284 125.796C0.399554 125.757 0.402259 126.38 0.797284 126.419C11.6714 127.487 22.5205 128.765 33.3611 130.131C48.8319 132.081 64.2883 134.145 79.7414 136.23C96.4574 138.485 113.169 140.775 129.876 143.099C146.395 145.395 162.909 147.735 179.431 150.015C194.757 152.13 210.088 154.326 225.462 156.062C226.263 156.152 227.065 156.244 227.868 156.309C228.267 156.342 228.265 155.719 227.868 155.687Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_7"
            d="M47.1717 85.3903C45.3917 86.8709 42.2539 94.1485 42.2539 94.1485C43.258 94.2548 44.2685 94.2868 45.2773 94.2443L44.9242 92.3121L46.2366 94.1672C46.7487 94.1123 47.2545 94.0097 47.7476 93.8607L47.2041 90.8868L48.901 93.2854C48.9136 93.2754 48.9265 93.2657 48.9388 93.2555C50.7188 91.7749 51.7662 88.8139 51.2782 86.642C50.7902 84.4701 48.9517 83.9097 47.1717 85.3903Z"
            fill="#4D7CEE"
          />
          <path
            id="Vector_8"
            d="M87.6423 49.7666C86.1263 51.3492 83.0625 58.6553 83.0625 58.6553C83.8511 58.6732 84.6397 58.619 85.4185 58.4935L85.3044 56.6535L86.1751 58.338C86.5907 58.238 86.9952 58.096 87.3822 57.9143L87.2067 55.0824L88.3324 57.2605C88.343 57.2497 88.354 57.2392 88.3644 57.2283C89.8804 55.6458 90.9477 52.6925 90.7483 50.632C90.5488 48.5715 89.1583 48.1841 87.6423 49.7666Z"
            fill="#FFD820"
          />
          <path
            id="Vector_9"
            d="M49.5769 142.292C47.4659 141.712 39.5957 142.496 39.5957 142.496C39.956 143.199 40.3794 143.868 40.8608 144.494L42.4207 143.516L41.3579 145.086C41.6438 145.405 41.9611 145.693 42.3051 145.947L44.7058 144.443L43.3317 146.473C43.3462 146.477 43.3606 146.482 43.3751 146.486C45.4861 147.066 48.5858 146.599 50.2984 145.441C52.011 144.283 51.688 142.873 49.5769 142.292Z"
            fill="#5B5973"
          />
          <path
            id="Vector_10"
            d="M55.3777 138.971C53.2666 138.39 45.3965 139.174 45.3965 139.174C45.7567 139.877 46.1802 140.546 46.6616 141.172L48.2214 140.195L47.1587 141.765C47.4446 142.083 47.7619 142.372 48.1059 142.626L50.5065 141.121L49.1324 143.151C49.1469 143.155 49.1614 143.16 49.1759 143.164C51.2869 143.745 54.3866 143.277 56.0992 142.119C57.8118 140.961 57.4888 139.552 55.3777 138.971Z"
            fill="#F4A28C"
          />
          <path
            id="Vector_11"
            d="M117.106 152.048C114.995 151.467 107.125 152.251 107.125 152.251C107.485 152.954 107.909 153.623 108.39 154.249L109.95 153.271L108.887 154.841C109.173 155.16 109.49 155.448 109.834 155.703L112.235 154.198L110.861 156.228C110.875 156.232 110.89 156.237 110.904 156.241C113.015 156.822 116.115 156.354 117.828 155.196C119.54 154.038 119.217 152.628 117.106 152.048Z"
            fill="#5B5973"
          />
          <path
            id="Vector_12"
            d="M55.3777 147.481C53.2666 146.9 45.3965 147.684 45.3965 147.684C45.7567 148.387 46.1802 149.056 46.6616 149.682L48.2214 148.705L47.1587 150.275C47.4446 150.593 47.7619 150.882 48.1059 151.136L50.5065 149.631L49.1324 151.661C49.1469 151.665 49.1614 151.67 49.1759 151.674C51.2869 152.255 54.3866 151.787 56.0992 150.629C57.8118 149.471 57.4888 148.062 55.3777 147.481Z"
            fill="#4D7CEE"
          />
          <path
            id="Vector_13"
            d="M148.674 1.32733C146.894 2.80796 143.756 10.0855 143.756 10.0855C144.76 10.1918 145.77 10.2238 146.779 10.1814L146.426 8.24907L147.739 10.1042C148.251 10.0493 148.756 9.94667 149.25 9.79774L148.706 6.82385L150.403 9.22242C150.416 9.21242 150.428 9.2027 150.441 9.19251C152.221 7.7119 153.268 4.75094 152.78 2.57903C152.292 0.407113 150.454 -0.153285 148.674 1.32733Z"
            fill="#4D7CEE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_103_5724">
          <rect
            width="229"
            height="157"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
