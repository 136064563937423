import {
  MetaShowerBatch,
  MetaShowerBatchStatus,
} from "../../../../../../dtos/metashower";

export interface IVideoComponentProps {
  status: MetaShowerBatchStatus;
  item: MetaShowerBatch;
  clickVideo: (item: MetaShowerBatch) => void;
  changeLoadFlag: () => void;
}

export enum DropdownItemEnum {
  More = 0,
  Preview = 1,
  Delete = 2,
}
