import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { EditableNodeProps } from "./props";

export const useAction = (props: EditableNodeProps) => {
  const { x, y, src, stageRef, text, handleUpdateData } = props;
  const [image, setImage] = useState<CanvasImageSource | undefined>(undefined);

  const [isSelected, setIsSelected] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [rotation, setRotation] = useState(0);

  const imageRef = useRef<Konva.Image>(null);
  const textRef = useRef<Konva.Text>(null);
  const transformerRef = useRef<Konva.Transformer>(null);

  const [nodeX, setNodeX] = useState(x ?? 50);
  const [nodeY, setNodeY] = useState(y ?? 50);

  // 加载图片
  const loadImage = () => {
    if (src) {
      const img = new Image();

      const fileType = src.split("/")[0] ? src.split("/")[0].split(":")[1] : "";

      if (fileType === "image") {
        img.setAttribute("crossOrigin", "Anonymous");

        img.src = src;

        img.onload = (e) => {
          setImage(img);
        };
      } else if (fileType === "video") {
        const video = document.createElement("video");
        video.width = stageRef.current?.width() ?? 0;
        video.height = stageRef.current?.height() ?? 0;
        video.setAttribute("crossOrigin", "Anonymous");
        video.src = src;
        video.currentTime = 2; // 第一帧
        video.oncanplay = (e) => {
          let canvas = document.createElement("canvas");
          canvas.width = stageRef.current?.width() ?? 0;
          canvas.height = stageRef.current?.height() ?? 0;
          let ctx = canvas.getContext("2d"); // 绘制2d

          ctx && ctx.drawImage(video, 0, 0, video.width, video.height);

          let imgUrl = canvas.toDataURL();
          const img = new Image();
          img.setAttribute("crossOrigin", "Anonymous");
          img.src = imgUrl;
          img.onload = (e) => {
            setImage(img);
          };
        };
      }
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    setIsDragging(false);

    const imgData = imageRef.current;
    const textData = textRef.current;
    const node = e.target;

    // 限制边界
    let newX = node.x();
    let newY = node.y();

    setNodeX(newX);
    setNodeY(newY);

    if ((src && imgData) || (text && textData)) {
      const element = src && imgData ? imageRef.current : textRef.current;
      const elementData = src && imgData ? imgData.attrs : textData?.attrs;

      if (element && elementData) {
        const xCoordinate = Number(elementData.x);
        const yCoordinate = Number(elementData.y);
        handleUpdateData((prev) => ({ ...prev, xCoordinate, yCoordinate }));
        element.to({ x: newX, y: newY });
      }
    }
  };

  // 处理缩放开始事件
  const handleResizeStart = () => {
    setIsResizing(true);
  };

  // 处理缩放结束事件
  const handleResizeEnd = (e: KonvaEventObject<Event>) => {
    setIsResizing(false);
    const node = e.target;

    const clientRect = e.target.getClientRect();

    const img = imageRef.current;

    if (img) {
      img.to({ width: node.width(), height: node.height() });

      handleUpdateData((prev) => ({
        ...prev,
        xCoordinate: clientRect.x,
        yCoordinate: clientRect.y,
        width: clientRect.width,
        height: clientRect.height,
      }));
    }
  };

  // 处理旋转事件
  const handleRotate = () => {
    setRotation((rotation + 90) % 360);
  };

  // 旋转变换的快捷键
  const handleRotateKeyDown = (e: KeyboardEvent) => {
    if (e.key === "r" && !isResizing) {
      handleRotate();
    }
  };

  useEffect(() => {
    loadImage();
  }, [src]);

  return {
    isSelected,
    transformerRef,
    image,
    nodeX,
    nodeY,
    rotation,
    imageRef,
    textRef,
    handleDragStart,
    handleDragEnd,
    handleResizeStart,
    handleResizeEnd,
    setIsSelected,
    handleRotateKeyDown,
  };
};

export default useAction;
