import { Quill } from "react-quill";

const BlockEmbed = Quill.import("blots/block/embed");

export enum Tone {
  FirstSound = 1,
  SecondSound,
  ThirdSound,
  FourthTone,
  FifthTone,
}

export const toneTitle = {
  [Tone.FirstSound]: "ā",
  [Tone.SecondSound]: "á",
  [Tone.ThirdSound]: "ǎ",
  [Tone.FourthTone]: "à",
  [Tone.FifthTone]: "a",
};

export enum SsmlTag {
  Digit, //数字
  ReadingNumbers, //读数字
  Reading, //读数
  Continuous, //连续
  Ventilation, //换气
  Pause, //停顿
  PolyphonicCharacter, //多音字
}

export const ssmlTagTitle = {
  [SsmlTag.Digit]: "数字",
  [SsmlTag.ReadingNumbers]: "读数字",
  [SsmlTag.Reading]: "读数",
  [SsmlTag.Continuous]: "连续",
  [SsmlTag.Ventilation]: "换气",
  [SsmlTag.Pause]: "停顿",
  [SsmlTag.PolyphonicCharacter]: "多音字",
};

export const ssmlTagKey = {
  [SsmlTag.Digit]: "currentSmml",
  [SsmlTag.ReadingNumbers]: "currentSmml",
  [SsmlTag.Reading]: "currentSmml",
  [SsmlTag.Continuous]: "currentSmml",
  [SsmlTag.Ventilation]: "currentSmml",
  [SsmlTag.Pause]: "currentSmml",
  [SsmlTag.PolyphonicCharacter]: "phoneme",
};

export interface IBlotStaticData {
  value: string;
  label: string;
  index?: number;
  length?: number;
  type?: SsmlTag;
}

//多音字
export class PhonemeBlot extends BlockEmbed {
  static blotName = "phoneme";
  static tagName = "span";

  static create(data: IBlotStaticData) {
    const node = document.createElement("span");

    const phonemeList = data.value?.split(" ");

    const { index, value, type, length, label } = data;

    const toneList: Tone[] = phonemeList?.map((item) => {
      const tone = Number(item.match(/\d+/)?.[0]);

      const newTone = tone >= 1 && tone <= 5 ? tone : 1;

      return isNaN(newTone) ? 1 : newTone;
    });

    const phonemeNode = (toneList: Tone[]) => {
      return `[${toneList?.map((item, index) =>
        index === toneList.length - 1 ? toneTitle[item] : toneTitle[item] + ","
      )}]`;
    };

    node.innerHTML = phonemeNode(toneList);
    node.classList.add("ql-phoneme"); // 添加自定义样式类
    node.style.color = "#8A8D95";
    node.setAttribute("index", String(index));
    node.setAttribute("length", String(length));
    node.setAttribute("type", String(type));
    node.setAttribute("label", label);
    node.setAttribute("ph", value);
    node.setAttribute("isSSMLDom", "true");
    node.setAttribute("contenteditable", "false");

    return node;
  }
}

//通用
export class CurrentSsmlTag extends BlockEmbed {
  static blotName = "currentSmml";
  static tagName = "span";

  static create(data: IBlotStaticData) {
    const node = document.createElement("span");

    const { type, index, length, label } = data;

    node.innerHTML = `[${ssmlTagTitle[type as SsmlTag]}]`;

    node.classList.add("ql-currentSsmlTag");
    node.setAttribute("index", String(index));
    node.setAttribute("length", String(length));
    node.setAttribute("type", String(type));
    node.setAttribute("label", label);
    node.setAttribute("isSSMLDom", "true");
    node.setAttribute("contenteditable", "false");

    node.style.color = "#8A8D95";

    node.style.backgroundColor =
      type === SsmlTag.Pause
        ? "#FFD4BF"
        : type === SsmlTag.Digit
        ? "#CAD8FA"
        : "#c6c6c6";

    return node;
  }
}
